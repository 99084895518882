const init: ISupplierPart = {
  partNumbersOE: null,
  partStandard: null,
  priceDiscountGroup: null,
  priceRetail: null,
  supplierDescription: null,
  supplierPartNo: null,
};

export default (state = init, action: any) => {
  switch (action.type) {
    case 'PARTS_LIST_FILTER_CLEAR':
      return { ...init };
    case 'PARTS_LIST_FILTER':
      return {
        ...state,
        ...action.value,
      };
    default:
      return { ...state };
  }
};
