import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

const MonthlyCron: React.FC<CronProps> = ({ value, translate, onChange }) => {
  const [cron, setCron] = useState<string[]>(value);
  const [every, setEvery] = useState<string>('');

  useEffect(() => {
    switch (cron[3]) {
      case 'L':
        setEvery('2');
        break;
      case 'LW':
        setEvery('3');
        break;
      default:
        cron[3].startsWith('L') ? setEvery('4') : setEvery('1');
        break;
    }
  }, [cron]);

  const onDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((parseInt(e.target.value) > 0 && parseInt(e.target.value) <= 31) || e.target.value === '') {
      let val = [
        '0',
        cron[1] === '*' ? '0' : cron[1],
        cron[2] === '*' ? '0' : cron[2],
        cron[3],
        '*',
        '?',
        '*',
      ];
      val[3] = `${e.target.value}`;
      setCron(val);
      onChange(val);
    }
  };

  const updateCronAt = (index: number[], value: string[]): void => {
    let val = [...cron];
    _.map(index, (i, x) => {
      val[i] = value[x];
    });
    setCron(val);
    onChange(val);
  };

  const onAtHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([0, 2], ['0', `${e.target.value}`]);
  };

  const onAtMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([0, 1], ['0', `${e.target.value}`]);
  };

  const changeInput = (e: React.ChangeEvent<HTMLInputElement>, val: string[]) => {
    setEvery(e.target.value);
    onChange(val);
  };

  return (
    <div className="tab-pane">
      <div className="well well-small">
        <input
          type="radio"
          onChange={(e) => {
            changeInput(e, [
              '0',
              cron[1] === '*' ? '0' : cron[1],
              cron[2] === '*' ? '0' : cron[2],
              '1',
              '*',
              '?',
              '*',
            ]);
          }}
          value="1"
          name="MonthlyRadio"
          checked={every === '1' ? true : false}
        />
        {translate('Day')}
        <input disabled={every !== '1'} type="number" value={cron[3]} onChange={onDayChange} />
        {translate('of every month(s)')}
      </div>
      <div className="well well-small">
        <span>{translate('Start time')}</span>
        <Hour onChange={onAtHourChange} value={cron[2]} />
        <Minutes onChange={onAtMinuteChange} value={cron[1]} />
      </div>
    </div>
  );
};

export default MonthlyCron;
