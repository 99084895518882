import 'react-image-gallery/styles/css/image-gallery.css';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connectModal } from 'redux-modal';
import React, { Component } from 'react';

import './DeleteDiscountModal.scss';

class DeleteDiscountModalContainer extends Component<any> {
  componentDidMount() {
    document.addEventListener('keydown', this.onEsc.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(event: any) {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      this.props.handleHide();
    }
  }

  render() {
    const {
      title,
      show,
      confirmButtonText,
      confirmButtonColor,
      handleHide,
      handleConfirm,
      dataItem,
    } = this.props;

    return (
      <div>
        <Modal isOpen={show} className="delete-discount-price-modal">
          <ModalBody className="modal-body">
            <div className="title">{title}</div>
            <div className="content">Do you really wan to delete this discount?</div>
          </ModalBody>
          <ModalFooter>
            <Button color={confirmButtonColor || 'danger'} onClick={handleHide}>
              {'Cancel'}
            </Button>
            <Button
              color={confirmButtonColor || 'danger'}
              onClick={() => handleConfirm(dataItem, handleHide)}>
              {confirmButtonText || 'Confirm'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export class DeleteDiscountModal extends Component<any> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(DeleteDiscountModalContainer);
    return <WrappedMyModal {...this.props} />;
  }
}
