/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Component } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  Label,
  ModalHeader,
  InputGroup,
  Table,
  Badge,
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { Steps, Panel, Tooltip, Whisper } from 'rsuite';
import Select from 'react-select';
import csv from 'csvtojson';
import arrayBufferToBuffer from 'arraybuffer-to-buffer';

import 'rsuite/dist/styles/rsuite-default.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import './FtpSetupModal.scss';
import StyledDropzone from '../common/Dropzone/StyledDropzone';
import { toast } from 'react-toastify';
import {
  ReduxFirestoreQueries,
  ReduxFirestoreQuerySetting,
  FirebaseReducer,
  FirestoreReducer,
  firestoreConnect,
} from 'react-redux-firebase';
import { connectModal } from 'redux-modal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Icon from 'react-icons-kit';
import { info } from 'react-icons-kit/feather/info';
import { alertTriangle } from 'react-icons-kit/feather/alertTriangle';
import { LoaderSmall } from '../common/LoaderSmall/LoaderSmall';
import Cron from '../common/CronBuilder/CronBuilder';
import 'react-cron-generator/dist/cron-builder.css';
import SwitchControl from '../common/SwitchControl/SwitchControl';
import moment from 'moment';
import { CSVParseParam } from 'csvtojson/v2/Parameters';

type fields =
  | 'partNumbersOE'
  | 'partStandard'
  | 'discountPercentage'
  | 'priceDiscountGroup'
  | 'priceRetail'
  | 'supplierDescription'
  | 'supplierPartNo';

const CRON_OPTIONS: { headers: ICronHeader[] } = {
  headers: ['DAILY', 'WEEKLY', 'MONTHLY'],
};

const FtpSetupModalContainer = (props: FtpSetupModalProps) => {
  const [ftpDetails, setFtpDetails] = useState<ISftp>({
    dataMap: {
      partNumbersOE: {
        default: '',
        reference: '',
        type: '',
        normalize: true,
        required: true,
      },
      partStandard: {
        default: 'oe',
        reference: '',
        type: '',
        normalize: false,
        required: false,
      },
      priceDiscountGroup: {
        default: '',
        reference: '',
        type: '',
        normalize: false,
        required: false,
      },
      discountPercentage: {
        default: '',
        reference: '',
        type: '',
        normalize: false,
        required: false,
      },
      priceRetail: {
        default: '',
        reference: '',
        type: '',
        normalize: false,
        required: true,
      },
      supplierDescription: {
        default: '',
        reference: '',
        type: '',
        normalize: false,
        required: true,
      },
      supplierPartNo: {
        default: '',
        reference: '',
        type: '',
        normalize: false,
        required: true,
      },
    },
    deleteFileAfterUpload: false,
    disableStrictVendorCheck: false,
    delimiter: null,
    scheduler: {
      active: false,
      cron: '',
      jobId: null,
    },
    protocol: 'sftp',
    filename: '',
    filesBackup: '',
    filesNew: '',
    host: '',
    password: '',
    port: 22,
    user: '',
    useStaticIp: true,
  });
  const [step, setStep] = useState(0);
  const [csvFields, setCsvFields] = useState<string[]>([]);
  const [canValidate, setCanValidate] = useState(false);
  const [isSftpSaving, setIsSftpSaving] = useState(false);

  useEffect(() => {
    const onEsc = (event: any) => {
      if (event.keyCode === 27) {
        // Do whatever when esc is pressed
        if (!_.isUndefined(props.toggleShow)) props.toggleShow(false);
      }
    };

    const cleanup = () => {
      document.removeEventListener('keydown', onEsc, false);
    };

    document.addEventListener('keydown', onEsc, false);

    return cleanup;
  }, []);

  useEffect(() => {
    if (!_.isUndefined(props.ftpDetails)) {
      setFtpDetails({
        ...ftpDetails,
        ...props.ftpDetails,
        password: '',
        dataMap: { ...ftpDetails.dataMap, ...props.ftpDetails.dataMap },
      });
    }
  }, [props.ftpDetails]);
  const isFieldMappingValid = () => {
    const isValid = _.map(ftpDetails.dataMap, (item, k) => {
      if (!item.required) {
        return true;
      }
      if (
        !_.isEmpty(item.reference) ||
        (_.isString(item.reference) && !_.isEmpty(item.reference.trim()))
      ) {
        return true;
      }
      return false;
    });
    return _.every(isValid);
  };

  const customSelectStyles = {
    control: () => ({
      alignItems: 'center',
      backgroundColor: '#fff',
      borderColor: 'hsl(0,0%,80%);',
      borderRadius: '0',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '26px',
      outline: '0 !important',
      transition: 'all 100ms',
      width: '200px',
    }),
  };

  const isValid = (field: fields) => {
    const required = ftpDetails.dataMap[field] ? ftpDetails.dataMap[field].required : false;
    const ref =
      ftpDetails.dataMap[field] && ftpDetails.dataMap[field].reference
        ? ftpDetails.dataMap[field].reference
        : null;
    if (!required) {
      return true;
    }
    return ref && !_.isEmpty(ref);
  };

  const renderFieldReference = (field: fields, isRequired = false) => {
    return _.isEmpty(csvFields) ? (
      <Input
        className={
          !canValidate || isValid(field)
            ? 'cstm-input app-multitext'
            : 'not-valid cstm-input app-multitext'
        }
        type="text"
        value={ftpDetails?.dataMap[field]?.reference ?? ''}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          setFtpDetails({
            ...ftpDetails,
            dataMap: {
              ...ftpDetails.dataMap,
              [field]: {
                ...ftpDetails.dataMap[field],
                reference: evt.target.value,
              },
            },
          });
        }}
        placeholder="Reference"
      />
    ) : (
      <CreatableSelect
        isClearable={true}
        className={
          !canValidate || isValid(field)
            ? 'cstm-input app-multitext'
            : 'not-valid cstm-input app-multitext'
        }
        styles={{
          control: () => ({
            alignItems: 'center',
            backgroundColor: '#fff',
            borderColor: 'hsl(0,0%,80%);',
            borderRadius: '0',
            borderStyle: 'solid',
            borderWidth: '1px',
            cursor: 'default',
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '26px',
            outline: '0 !important',
            transition: 'all 100ms',
            width: '200px',
          }),
        }}
        onChange={(evt: any) => {
          const newDetails = _.cloneDeep(ftpDetails) as ISftp;
          newDetails.dataMap[field].reference = evt ? evt.value : '';
          setFtpDetails(newDetails);
        }}
        isMulti={false}
        value={
          ftpDetails?.dataMap[field]?.reference
            ? {
                label: ftpDetails.dataMap[field].reference,
                value: ftpDetails.dataMap[field].reference,
              }
            : {}
        }
        options={_.map(csvFields, (item) => ({ label: item, value: item }))}
      />
    );
  };

  const renderFieldDefault = (field: fields) => {
    return (
      <Input
        type="text"
        value={ftpDetails?.dataMap[field]?.default ?? ''}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const newDetails = _.cloneDeep(ftpDetails) as ISftp;
          newDetails.dataMap[field].default = evt.target.value;
          setFtpDetails(newDetails);
        }}
        placeholder="From File"
      />
    );
  };

  const getFields = async (csvFile: File, delimiter: string | null) => {
    try {
      const fr = new FileReader();

      fr.onload = async (evt): Promise<void> => {
        const result = evt.target?.result;
        const csvData = arrayBufferToBuffer(result);
        const csvString = csvData.toString('utf-8');
        let opts: Partial<CSVParseParam> | undefined = undefined;
        if (delimiter) {
          opts = {
            delimiter,
          };
        }
        const jsonData = await csv(opts).fromString(csvString);
        if (!_.isEmpty(jsonData)) {
          setCsvFields(Object.keys(jsonData[0]));
        }
      };

      fr.readAsArrayBuffer(csvFile);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderRequiredStar = (key: string) => {
    const isRequired = ftpDetails ? ftpDetails.dataMap[key].required : false;
    if (!isRequired) {
      return false;
    }
    return <span className="required-star">*</span>;
  };

  const tooltip = <Tooltip>Field is case sensitive.</Tooltip>;

  return (
    <Modal className="ftpDetails" isOpen={props.show}>
      <ModalHeader>FTP Details</ModalHeader>
      <ModalBody className="modal-body">
        <Steps current={step} vertical>
          <Steps.Item title="FTP Configuration" onClick={(): void => setStep(0)} />
          <Steps.Item title="Upload CSV" onClick={(): void => setStep(1)} />
          <Steps.Item title="Field Mapping" onClick={(): void => setStep(2)} />
          <Steps.Item title="Schedule" onClick={(): void => setStep(3)} />
          <Steps.Item title="Logs" onClick={(): void => setStep(4)} />
        </Steps>
        <Panel style={{ overflow: 'visible' }}>
          {step === 0 && (
            <div id="step1">
              <FormGroup>
                <Label>
                  <span>Protocol</span>
                </Label>
                <InputGroup>
                  <Select
                    className="cstm-input app-dropdown"
                    styles={{ ...customSelectStyles }}
                    onChange={(evt: any) => {
                      const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                      newDetails.protocol = evt.value;
                      setFtpDetails(newDetails);
                    }}
                    isMulti={false}
                    value={
                      ftpDetails?.protocol
                        ? {
                            label: ftpDetails.protocol.toUpperCase(),
                            value: ftpDetails.protocol,
                          }
                        : {
                            label: 'SFTP (FTP over SSH)',
                            value: 'sftp',
                          }
                    }
                    options={[
                      {
                        label: 'FTPS (FTP over SSL/TLS)',
                        value: 'ftps',
                      },
                      {
                        label: 'SFTP (FTP over SSH)',
                        value: 'sftp',
                      },
                    ]}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Disable host key check</span>
                </Label>
                <div className="app-checkbox-container">
                  <label className="checkbox">
                    <Input
                      type="checkbox"
                      checked={ftpDetails.disableStrictVendorCheck}
                      onChange={() => {
                        const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                        newDetails.disableStrictVendorCheck = !ftpDetails.disableStrictVendorCheck;
                        setFtpDetails(newDetails);
                      }}
                    />
                    {/* this span needs to be here */}
                    <span></span>
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Use static IP</span>
                </Label>
                <div className="app-checkbox-container">
                  <label className="checkbox">
                    <Input
                      type="checkbox"
                      checked={ftpDetails.useStaticIp ?? true}
                      onChange={() => {
                        const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                        newDetails.useStaticIp = !(ftpDetails.useStaticIp ?? true);
                        setFtpDetails(newDetails);
                      }}
                    />
                    {/* this span needs to be here */}
                    <span></span>
                  </label>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Host</Label>
                <Input
                  type="text"
                  value={ftpDetails?.host ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.host = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Port</Label>
                <Input
                  type="number"
                  value={ftpDetails?.port ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.port = Number(evt.target.value);
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Username</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="text"
                  value={ftpDetails?.user ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.user = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Password</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="password"
                  value={ftpDetails?.password ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.password = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Filename</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="text"
                  value={ftpDetails?.filename ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.filename = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>New File Path</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="text"
                  value={ftpDetails?.filesNew ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.filesNew = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Backup File Path</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="text"
                  value={ftpDetails?.filesBackup ?? ''}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    newDetails.filesBackup = evt.target.value;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Delimiter</span>
                  <Whisper trigger="hover" speaker={tooltip} placement="auto">
                    <Icon icon={alertTriangle} size={16} />
                  </Whisper>
                </Label>
                <Input
                  type="text"
                  value={ftpDetails?.delimiter ?? ''}
                  placeholder={`,`}
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                    const delimiter = evt.target.value.trim();
                    newDetails.delimiter = delimiter;
                    setFtpDetails(newDetails);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  <span>Delete file after upload</span>
                </Label>
                <div className="app-checkbox-container">
                  <label className="checkbox">
                    <Input
                      type="checkbox"
                      checked={ftpDetails.deleteFileAfterUpload}
                      onChange={() => {
                        const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                        newDetails.deleteFileAfterUpload = !ftpDetails.deleteFileAfterUpload;
                        setFtpDetails(newDetails);
                      }}
                    />
                    {/* this span needs to be here */}
                    <span></span>
                  </label>
                </div>
              </FormGroup>
            </div>
          )}
          {step === 1 && (
            <div>
              <FormGroup>
                <Label>Sample CSV File (Optional)</Label>
                <StyledDropzone
                  onDrop={async (acceptedFiles: any) => {
                    const delimiter = ftpDetails.delimiter !== '' ? ftpDetails.delimiter : null;
                    await getFields(acceptedFiles[0], delimiter);
                    setStep(step + 1);
                  }}
                  accept=".csv"
                  filename=""
                />
              </FormGroup>
            </div>
          )}
          {step === 2 && (
            <div id="step2" className="field-mapping">
              <FormGroup>
                {ftpDetails.dataMap['partNumbersOE'] &&
                ftpDetails.dataMap['partNumbersOE'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>OE Part Number{renderRequiredStar('partNumbersOE')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>OE Part Number{renderRequiredStar('partNumbersOE')}</span>
                  </Label>
                )}
                <InputGroup>{renderFieldReference('partNumbersOE')}</InputGroup>
              </FormGroup>

              <FormGroup>
                {ftpDetails.dataMap['partStandard'] &&
                ftpDetails.dataMap['partStandard'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Part Standard{renderRequiredStar('partStandard')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Part Standard{renderRequiredStar('partStandard')}</span>
                  </Label>
                )}
                <InputGroup>
                  <Select
                    className="cstm-input app-dropdown"
                    styles={{ ...customSelectStyles }}
                    onChange={(evt: any) => {
                      const newDetails = _.cloneDeep(ftpDetails) as ISftp;
                      newDetails.dataMap['partStandard'].default = evt.value;
                      setFtpDetails(newDetails);
                    }}
                    isMulti={false}
                    value={
                      ftpDetails?.dataMap['partStandard'].default
                        ? {
                            label: ftpDetails.dataMap['partStandard'].default.toLowerCase(),
                            value: ftpDetails.dataMap['partStandard'].default,
                          }
                        : {}
                    }
                    options={props.partStandards}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                {ftpDetails.dataMap['priceDiscountGroup'] &&
                ftpDetails.dataMap['priceDiscountGroup'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Discount Group{renderRequiredStar('priceDiscountGroup')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Discount Group{renderRequiredStar('priceDiscountGroup')}</span>
                  </Label>
                )}
                <InputGroup>
                  {renderFieldReference('priceDiscountGroup')}
                  {renderFieldDefault('priceDiscountGroup')}
                </InputGroup>
              </FormGroup>
              <FormGroup>
                {ftpDetails.dataMap['discountPercentage'] &&
                ftpDetails.dataMap['discountPercentage'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Discount Percentage{renderRequiredStar('discountPercentage')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Discount Percentage{renderRequiredStar('discountPercentage')}</span>
                  </Label>
                )}
                <InputGroup>{renderFieldReference('discountPercentage')}</InputGroup>
              </FormGroup>
              <FormGroup>
                {ftpDetails.dataMap['priceRetail'] &&
                ftpDetails.dataMap['priceRetail'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Retail Price{renderRequiredStar('priceRetail')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Retail Price{renderRequiredStar('priceRetail')}</span>
                  </Label>
                )}
                <InputGroup>{renderFieldReference('priceRetail')}</InputGroup>
              </FormGroup>
              <FormGroup>
                {ftpDetails.dataMap['supplierDescription'] &&
                ftpDetails.dataMap['supplierDescription'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Supplier Description{renderRequiredStar('supplierDescription')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Supplier Description{renderRequiredStar('supplierDescription')}</span>
                  </Label>
                )}
                <InputGroup>{renderFieldReference('supplierDescription')}</InputGroup>
              </FormGroup>
              <FormGroup>
                {ftpDetails.dataMap['supplierPartNo'] &&
                ftpDetails.dataMap['supplierPartNo'].normalize ? (
                  <Label title="This field will be normalized">
                    <span>Supplier Part No{renderRequiredStar('supplierPartNo')}</span>
                    <Icon icon={info} size={16} />
                  </Label>
                ) : (
                  <Label>
                    <span>Supplier Part No{renderRequiredStar('supplierPartNo')}</span>
                  </Label>
                )}
                <InputGroup>{renderFieldReference('supplierPartNo')}</InputGroup>
              </FormGroup>
            </div>
          )}
          {step === 3 && (
            <div id="step3" className="scheduler">
              <div className="scheduler-flex">
                <Cron
                  onChange={(e: any) => {
                    setFtpDetails({
                      ...ftpDetails,
                      scheduler: { ...ftpDetails.scheduler, cron: e },
                    });
                  }}
                  value={ftpDetails.scheduler.cron}
                  options={CRON_OPTIONS}
                  active={ftpDetails.scheduler.active}
                  showResultText={true}
                  showResultCron={false}
                />
                <div className="scheduler-switch">
                  <SwitchControl
                    field={{ label: '', required: false }}
                    showLabel={false}
                    onChange={(checked: boolean) => {
                      setFtpDetails({
                        ...ftpDetails,
                        scheduler: { ...ftpDetails.scheduler, active: checked },
                      });
                    }}
                    value={ftpDetails.scheduler.active || false}
                  />{' '}
                  <span className="label">Active</span>
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <div id="step4">
              <Table hover>
                <thead>
                  <tr>
                    <th className="col-start">Start</th>
                    <th className="col-end">End</th>
                    <th className="col-success">Success</th>
                  </tr>
                </thead>
                <tbody>
                  {_.isEmpty(props.history) ? (
                    <div className="col-no-data">No data</div>
                  ) : (
                    props.history.map((historyDoc, index: number) => {
                      return (
                        <tr key={index}>
                          <td className="col-start">{historyDoc.start}</td>
                          <td className="col-end">{historyDoc.end}</td>
                          <td className="col-success">
                            {_.isBoolean(historyDoc.success) ? (
                              <Badge color={historyDoc.success ? 'primary' : 'secondary'}>
                                {historyDoc.success ? 'Yes' : 'No'}
                              </Badge>
                            ) : (
                              historyDoc.success
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Panel>
      </ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          disabled={isSftpSaving}
          onClick={(): void => {
            props.handleHide();
          }}>
          Cancel
        </Button>
        <Button
          disabled={step === 0 || isSftpSaving}
          color="secondary"
          onClick={(): void => {
            setStep(step - 1);
          }}>
          Back
        </Button>
        {isSftpSaving ? (
          LoaderSmall()
        ) : (
          <Button
            color="primary"
            onClick={async (): Promise<void> => {
              if (step < 4) {
                setStep(step + 1);
              } else {
                setCanValidate(true);

                if (isFieldMappingValid()) {
                  setIsSftpSaving(true);
                  props
                    .saveFtpDetails(ftpDetails as ISftp)
                    .then(() => {
                      setIsSftpSaving(false);
                      props.handleHide();
                    })
                    .catch(() => {
                      setIsSftpSaving(false);
                    });
                }
              }
            }}>
            {step === 4 ? 'Confirm' : 'Next'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

const preMapStateToProps = (state: any, props: any) => {
  const {
    firebase: { profile },
  }: {
    firebase: FirebaseReducer.Reducer;
  } = state;
  const { supplierGroup, priceFileId } = props;
  return {
    profile,
    supplierGroup,
    priceFileId,
  };
};

const mapQueryToProps = ({
  profile,
  supplierGroup,
  priceFileId,
}: {
  profile: any;
  supplierGroup: string;
  priceFileId: string;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }

  const partStandards: ReduxFirestoreQuerySetting = {
    collection: 'reference',
    doc: 'partStandards',
    storeAs: 'part_standards',
  };

  let history: ReduxFirestoreQuerySetting = {};

  if (supplierGroup && priceFileId) {
    history = {
      collection: `/suppliers/${supplierGroup}/priceFiles/${priceFileId}/history`,
      storeAs: 'history',
    };
    return [partStandards, history];
  }

  return [partStandards];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
  }: {
    firestore: FirestoreReducer.Reducer;
  } = state;
  const partStandardsObj = firestore.data.part_standards;
  const historyObj = firestore.data.history;
  const isLoadingPartStandardsObj = firestore.status.requesting.part_standards ?? true;
  const isLoadingHistoryObj = firestore.status.requesting.history;

  let partStandards: { value: string; label: string }[] = [];

  if (!isLoadingPartStandardsObj && partStandardsObj) {
    partStandards = Object.keys(partStandardsObj).map((partStandard) => ({
      label: partStandard,
      value: partStandard.toLowerCase(),
    }));
  }

  let history: {
    start: string;
    end: string;
    success: any;
  }[] = [];

  if (historyObj && !isLoadingHistoryObj) {
    history = Object.values(historyObj)
      .map((historyDoc: any) => ({
        start: _.isNil(historyDoc.start)
          ? '-'
          : moment(historyDoc.start.toDate()).format('YYYY-MM-DD HH:mm'),
        end: _.isNil(historyDoc.end)
          ? '-'
          : moment(historyDoc.end.toDate()).format('YYYY-MM-DD HH:mm'),
        success:
          !_.isNil(historyDoc.config) &&
          !_.isNil(historyDoc.config.status) &&
          !_.isNil(historyDoc.config.status.success) &&
          historyDoc.config.status.success,
      }))
      .sort((a, b) => (a.start > b.start ? -1 : 1));
  }

  return {
    partStandards,
    history,
  };
};

export class FtpSetupModal extends Component<any> {
  render(): JSX.Element {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(
      compose(
        connect(preMapStateToProps),
        firestoreConnect(mapQueryToProps),
        connect(mapStateToProps),
      )(FtpSetupModalContainer) as any,
    );
    return <WrappedMyModal {...this.props} />;
  }
}
