import React from 'react';
import { connect } from 'react-redux';
import { FirebaseReducer, getFirebase } from 'react-redux-firebase';
import { compose } from 'redux';
import Icon from 'react-icons-kit';
import { logOut } from 'react-icons-kit/feather/logOut';

import '../../styles/Header.scss';

const Header = (props: FirebaseReducer.Reducer & HeaderProps) => {
  const logout = () => {
    props.firebase.logout();
  };

  return (
    <header className="main-header">
      <div className="header-left">MySupplier</div>
      <div className="header-right">
        <span className="user-name">{props.profile.displayName || props.profile.email}</span>
        <div className="logout">
          <Icon icon={logOut} size={20} onClick={logout} className="logout-icon" />
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = ({ firebase: { profile } }: { firebase: FirebaseReducer.Reducer }) => {
  return {
    profile,
    firebase: getFirebase(),
  };
};

export default compose<React.ComponentType<HeaderProps>>(connect(mapStateToProps))(Header);
