import { GridColumn as Column, Grid, GridCellProps, GridToolbar } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { show } from 'redux-modal';

import './styles.scss';
import DiscountEditCell from './DiscountEditCell';
import { GridCellUpdate } from './DiscountCommandCell';
import { UpdateDiscount } from '../../../actions/discounts';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { LoadingOverlayExtended } from '../../common/LoaderOverlay/LoaderOverlay';

const priceRetailFormatted = (props: GridCellProps) => <DiscountEditCell {...props} />;

declare interface IDataItem {
  id: number;
  group: string;
  discount: number;
  inEdit: boolean | undefined;
}

const DiscountGrid = (props: DiscountGridProps) => {
  const [gridData, setGridData] = useState<any>();
  const [isInEdit, setIsInEdit] = useState<boolean>(false);
  const [isInUpdating, setIsInUpdating] = useState<boolean>(false);

  useEffect(() => {
    convertValuesToData(props.values);
    setIsInEdit(false);
  }, [props.values]);

  const convertValuesToData = (values: any) => {
    if (_.isEmpty(values)) {
      setGridData([]);
    } else {
      const data: IDataItem[] = Object.keys(values)
        .map((key, index) => ({
          id: index,
          group: key,
          discount: values[key],
          inEdit: false,
        }))
        .sort((a: IDataItem, b: IDataItem) => (a.group > b.group ? 1 : b.group > a.group ? -1 : 0));
      setGridData(data);
    }
  };

  const dataHasChanged = (data: IDataItem[] | undefined) => {
    if (!_.isUndefined(data)) {
      const newValues: any = {};
      data.forEach((item) => {
        newValues[item.group] = item.group;
      });
    }
  };

  const itemChange = (event: any) => {
    const data = gridData?.map((item: IDataItem) =>
      item.id === event.dataItem.id ? { ...item, [event.field]: event.value } : item,
    );
    setGridData(data);
  };

  const enterEdit = (dataItem: any) => {
    setIsInEdit(true);
    setGridData(
      gridData.map((item: IDataItem) =>
        item.id === dataItem.id ? { ...item, inEdit: true } : item,
      ),
    );
  };

  const removeItem = (data: any, item: any) => {
    const index = data.findIndex((p: any) => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data.splice(index, 1);
    }
  };

  const updateDiscount = (
    action: 'ADD_DISCOUNT' | 'REMOVE_DISCOUNT' | 'UPDATE_DISCOUNT',
    data: any,
    handleHide?: () => void,
  ) => {
    const discountsToObj = data.reduce(function (map: any, obj: any) {
      map[obj.group] = obj.discount;
      return map;
    }, {});

    setIsInUpdating(true);

    props
      .UpdateDiscount({
        action,
        discountsObj: discountsToObj,
        groupId: props.groupId,
        priceFileId: props.priceFileId,
        discountGroup: props.discountGroup,
      })
      .then(() => {
        setGridData(
          data.sort((a: IDataItem, b: IDataItem) =>
            a.group > b.group ? 1 : b.group > a.group ? -1 : 0,
          ),
        );
        dataHasChanged(data);
      })
      .catch((ex: any) => {
        console.error('CSTM_EX', ex);
      })
      .finally(() => {
        setIsInUpdating(false);
        handleHide && handleHide();
      });
  };

  const remove = (dataItem: any, handleHide: () => any) => {
    const data = [...gridData];
    removeItem(data, dataItem);
    updateDiscount('REMOVE_DISCOUNT', data, handleHide);
  };

  const updateItem = (data: any, item: IDataItem) => {
    const index = data.findIndex((p: IDataItem) => p.id === item.id);
    if (index > -1) {
      data[index] = { ...item };
    }
  };

  const update = (dataItem: IDataItem) => {
    if (_.isEmpty(dataItem.group)) {
      toast.error('Please enter group');
      return;
    }

    if (gridData.findIndex((o: any) => o.group === dataItem.group && !o.inEdit) >= 0) {
      toast.error('Discount group already exists');
      return;
    }

    const data = [...gridData];
    const updatedItem = { ...dataItem, inEdit: undefined };
    updateItem(data, updatedItem);
    updateDiscount('UPDATE_DISCOUNT', data);
  };

  const cancel = () => {
    convertValuesToData(props.values);
    setIsInEdit(false);
  };

  const add = () => {
    const newItem = {
      inEdit: true,
      discount: 0,
      id: gridData.length,
    };

    const data = [...gridData, newItem];

    setIsInEdit(true);
    setGridData(data);
  };

  const openDeleteDiscountModal = (dataItem: any) => {
    const modalContent = {
      title: 'Delete discount',
      confirmButtonText: 'Delete',
      confirmButtonColor: 'primary',
      handleConfirm: remove,
      dataItem: dataItem,
    };

    if (!_.isUndefined(props.show)) props.show('DeleteDiscountModal', modalContent);
  };

  const gridCellUpdate = GridCellUpdate({
    edit: enterEdit,
    remove: openDeleteDiscountModal,
    update,
    cancel,
    editField: 'inEdit',
    onePartInEdit: isInEdit,
    disabled: props.disabled ?? false,
  });

  return (
    <div className="discount-prices-grid" id="discount-prices-grid">
      <LoadingOverlayExtended loading={isInUpdating}>
        <Grid data={gridData} onItemChange={itemChange} editField="inEdit" scrollable="scrollable">
          <GridToolbar>
            <div>
              <Button
                color="primary"
                title="Add new"
                className="add-discount-btn"
                onClick={add}
                disabled={isInEdit}>
                Add new
              </Button>
            </div>
          </GridToolbar>
          <Column field="group" editor="text" title="Group" />
          <Column
            field="discount"
            cell={priceRetailFormatted}
            width="70"
            editor="numeric"
            title="Discount"
          />
          <Column cell={gridCellUpdate} title="Edit" width="80" />
        </Grid>
      </LoadingOverlayExtended>
    </div>
  );
};

export default connect(null, { UpdateDiscount, show })(DiscountGrid);
