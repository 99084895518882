import Minutes from '../cron-tab/minutes';
import Daily from '../cron-tab/daily';
import Hourly from '../cron-tab/hourly';
import Weekly from '../cron-tab/weekly';
import Monthly from '../cron-tab/monthly';

export const HEADER: { [key: string]: ICronHeader } = {
  MINUTES: 'MINUTES',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

const HEADER_VALUES: { [key: string]: ICronHeaderValue } = {
  MINUTES: 'Minutes',
  HOURLY: 'Hourly',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
};

const defaultTabs = [HEADER_VALUES.DAILY, HEADER_VALUES.WEEKLY, HEADER_VALUES.MONTHLY];

export const metadata: {
  [key: string]: { component: React.FC<CronProps>; initialCron: string[] };
} = {
  minutes: {
    component: Minutes,
    initialCron: ['0', '0/1', '*', '*', '*', '?', '*'],
  },
  hourly: {
    component: Hourly,
    initialCron: ['0', '0', '0', '*', '*', '?', '*'],
  },
  daily: {
    component: Daily,
    initialCron: ['0', '0', '0', '*', '*', '?', '*'],
  },
  weekly: {
    component: Weekly,
    initialCron: ['0', '0', '0', '?', '*', '*', '*'],
  },
  monthly: {
    component: Monthly,
    initialCron: ['0', '0', '0', '1', '*', '?', '*'],
  },
};

const validateHeaders = (headers: ICronHeader[]): ICronHeaderValue[] => {
  const validatedHeaders: ICronHeaderValue[] = [];
  headers.forEach((header) => {
    if (!HEADER_VALUES[header]) {
      throw new Error('Invalid header ' + header);
      // Avoid duplicates
    } else if (validatedHeaders.indexOf(HEADER_VALUES[header]) === -1) {
      validatedHeaders.push(HEADER_VALUES[header]);
    }
  });
  return validatedHeaders;
};

/**
 * Validate and load headers
 * @param {*} options
 */
export const loadHeaders = (options: ICronOptions | undefined): ICronHeaderValue[] => {
  if (options) {
    if (options.headers) {
      if (!options.headers.length) {
        throw new Error('Atleast one header is required.');
      }
      return validateHeaders(options.headers);
    }
  }
  return defaultTabs;
};
