import { functions } from '../config/firebase';
import { IPart } from '../typings/IPart';
import { ActionCreator, Dispatch } from 'redux';
import { UPDATE_PART, REMOVE_PART } from './ActionTypes';

export const UpdatePart: ActionCreator<any> = async (
  newPart: IPart,
  supplierGroup: string,
  supplierId: string,
  userEmail: string,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_PART,
        payload: newPart,
      });
      const updatePart = functions.httpsCallable('UpdatePart');
      const updated = await updatePart({ part: newPart, supplierGroup, supplierId, userEmail });
      return updated.data;
    } catch (error) {
      console.error('ACTION_ERR:', error);
    }
  };
};

export const RemovePart: ActionCreator<any> = async (
  supplierPartNo: string,
  supplierGroup: string,
  supplierId: string,
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: REMOVE_PART,
        payload: supplierPartNo,
      });
      const removePart = functions.httpsCallable('RemovePart');
      const resp = await removePart({ supplierPartNo, supplierGroup, supplierId });
      return resp.data;
    } catch (error) {
      console.error('ACTION_ERR:', error);
    }
  };
};

export const SyncPriceFile: ActionCreator<any> = async (groupId: string, priceFileId: string) => {
  return async () => {
    try {
      const triggerSFTP = functions.httpsCallable('TriggerSFTP');
      const updated = await triggerSFTP({ groupId, priceFileId });
      return updated.data;
    } catch (error) {
      console.error('ACTION_ERR:', error);
    }
  };
};
