import _ from 'lodash';
import React, { useState } from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';

const HourlyCron: React.FC<CronProps> = ({ value, onChange, translate }) => {
  const [cron, setCron] = useState<string[]>(value);
  const [every, setEvery] = useState<boolean>(false);

  const onHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    let val = every ? [...cron] : ['0', '0', '*', '*', '*', '?', '*'];

    if ((Number(value) > 0 && Number(value) < 24) || value === '') {
      val[2] = value ? `0/${value}` : value;
      val[3] = '*';
      setCron(val);
      onChange(val);
    }
  };

  const updateCronAt = (index: number[], value: string[]): void => {
    let val = [...cron];
    _.map(index, (i, x) => {
      val[i] = value[x];
    });
    setCron(val);
    onChange(val);
  };

  const onAtHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([2], [`${e.target.value}`]);
  };

  const onAtMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([1], [`${e.target.value}`]);
  };
  return (
    <div className="tab-content">
      <div className="tab-pane active">
        <div className="well well-small">
          <input
            type="radio"
            onChange={(e) => {
              setEvery(true);
              onChange(['0', '0', '0/1', '*', '*', '?', '*']);
            }}
            checked={every}
          />{' '}
          <span> {translate('Every')} </span>{' '}
          <input
            disabled={!every}
            type="Number"
            onChange={onHourChange}
            value={cron[2].split('/')[1] ? cron[2].split('/')[1] : ''}
          />{' '}
          <span> {translate('hour(s)')} </span>{' '}
        </div>{' '}
        <div className="well well-small">
          <input
            type="radio"
            onChange={(e) => {
              setEvery(false);
              onChange();
            }}
            checked={!every}
          />{' '}
          <span className=""> {translate('At')} </span>{' '}
          <Hour disabled={every} onChange={onAtHourChange} value={cron[2]} />{' '}
          <Minutes disabled={every} onChange={onAtMinuteChange} value={cron[1]} />
        </div>
      </div>
    </div>
  );
};

export default HourlyCron;
