import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { reducer as modal } from 'redux-modal';

import PaginationReducer from './Pagination';
import PartsListFilter from './PartsListFilter';
import SupplierReducer from './Supplier';

const rootReducer = combineReducers({
  modal,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  pagination: PaginationReducer,
  partsListFilter: PartsListFilter,
  supplier: SupplierReducer,
});

export default rootReducer;
