import { functions } from '../config/firebase';
import { ActionCreator, Dispatch } from 'redux';

export const UpdateDiscount: ActionCreator<any> = async (updateDiscount: {
  action: 'ADD_DISCOUNT' | 'REMOVE_DISCOUNT' | 'UPDATE_DISCOUNT';
  discountsObj: any;
  groupId: string;
  priceFileId: string;
  discountGroup: string;
}) => {
  return async (dispatch: Dispatch) => {
    try {
      const { action } = updateDiscount;

      dispatch({
        type: action,
      });

      const callabelFn = functions.httpsCallable('UpdateDiscount');
      const callableFnResult = await callabelFn(updateDiscount);

      return callableFnResult.data;
    } catch (error) {
      console.error('ACTION_ERR:', error);
    }
  };
};
