import React, { useState, useEffect } from 'react';
import { InputGroup, Input } from 'reactstrap';
import _ from 'lodash';
import numeral from 'numeral';
import { GridCellProps } from '@progress/kendo-react-grid';

import './style.scss';

const PriceEdit = (props: GridCellProps) => {
  const [dataItem, setDataItem] = useState<any>(null);
  const [dataValue, setDataValue] = useState<string>('');

  useEffect(() => {
    if (!_.isUndefined(props.field)) {
      setDataItem(props.dataItem);
      setDataValue(props.dataItem[props.field] === null ? '' : props.dataItem[props.field]);
    }
  }, [props.dataItem, props.field]);

  const handleChange = (e: any) => {
    if (!_.isUndefined(props.onChange)) {
      props.onChange({
        dataItem: dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value,
      });
    }
  };

  return !_.isNull(dataItem) ? (
    <td className="price-retail">
      {!dataItem.inEdit ? (
        <div className="retail-price-cell">
          <span>R&nbsp;</span>
          <span className="amount">{numeral(dataValue ?? 0).format('#,###.##')}</span>
        </div>
      ) : (
        <div>
          <InputGroup>
            <Input
              className="price-edit-field"
              type="number"
              placeholder="0.00"
              onChange={handleChange}
              value={dataValue}
            />
          </InputGroup>
        </div>
      )}
    </td>
  ) : (
    <td></td>
  );
};

export default PriceEdit;
