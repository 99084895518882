import { Col, FormGroup, Label, Row } from 'reactstrap';
import React from 'react';
import Switch from 'react-switch';

import { SwitchControlProps } from './typings';

const SwitchControl = ({
  field,
  value,
  onChange,
  disabled = false,
  showLabel = true,
  additionalClasses = [],
}: SwitchControlProps) => (
  <FormGroup style={{ display: 'flex' }}>
    <Row style={{ width: '100%' }}>
      {showLabel && (
        <Col xs="5">
          <Label className="display-block">{field.label}</Label>
        </Col>
      )}
      <Col xs={showLabel ? '7' : '12'} style={{ paddingLeft: showLabel ? '25px' : 'auto' }}>
        <Switch
          onColor="#5C9CDC"
          onChange={(checked) => onChange(checked)}
          checked={value}
          disabled={disabled}
          height={20}
          width={40}
          // className={additionalClasses}
        />
      </Col>
    </Row>
  </FormGroup>
);
export default SwitchControl;
