import { functions } from '../config/firebase';

export const SaveFtpDetails = async (
  supplierGroup: string,
  pricefile: string,
  ftpDetails: ISftp,
): Promise<void> => {
  const saveFtpDetails = functions.httpsCallable('SaveFtpDetails');
  await saveFtpDetails({ supplierGroup, pricefile, ftpDetails });
};
