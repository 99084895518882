import _ from 'lodash';

import { FirebaseOptions } from '@firebase/app-types';

const devConfig: FirebaseOptions = {
  apiKey: 'AIzaSyCmdssIXURD-HQmywPCTwp8a4BDTrBXUUs',
  authDomain: 'claimhandler-suppliers.firebaseapp.com',
  databaseURL: 'https://claimhandler-suppliers.firebaseio.com',
  projectId: 'claimhandler-suppliers',
  storageBucket: 'claimhandler-suppliers.appspot.com',
  messagingSenderId: '172738450605',
  appId: '1:172738450605:web:3102fe9ced619fd02ce224',
  measurementId: 'G-6YE123Z7QH',
};

const prodConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAhw8a7xq5jhhHE3fPMHe83wPzYZe-zGNI',
  authDomain: 'mysupplier-production.firebaseapp.com',
  databaseURL: 'https://mysupplier-production.firebaseio.com',
  projectId: 'mysupplier-production',
  storageBucket: 'mysupplier-production.appspot.com',
  messagingSenderId: '36651039287',
  appId: '1:36651039287:web:a5baa03023b97b72141db2',
  measurementId: 'G-SBVG8HFZ1Y',
};

const isProd =
  !_.isUndefined(process.env.GCLOUD_PROJECT) && process.env.GCLOUD_PROJECT === prodConfig.projectId;
let config = devConfig;
if (isProd) {
  config = prodConfig;
}

if (!isProd) {
  console.warn('Serving in development mode');
}

export const FIREBASE_CONFIG = config;
export const IS_PROD = isProd;
export const IS_LOCAL = process.env.LOCAL_MACHINE === 'true';
