import React, { useState } from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';
import { compose } from 'redux';
import { FormGroup, Button } from 'reactstrap';

import '../../styles/PartsFilterForm.scss';
import DropDown from './DropDown/DropDown';
import { FirestoreReducer } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

const PartsFilterForm = (props: any) => {
  const init: ISupplierPart = {
    partNumbersOE: null,
    partStandard: null,
    priceDiscountGroup: null,
    priceRetail: null,
    supplierDescription: null,
    supplierPartNo: null,
  };

  const [filterForm, setFilterForm] = useState<ISupplierPart>(init);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    props.filterPartsList(filterForm);
  };

  const handleClearFilter = () => {
    props.clearfilterPartsList();
    setFilterForm(init);
  };

  const handleInputChange = (event: any, fieldName: string) => {
    setFilterForm({ ...filterForm, [fieldName]: event.target.value });
  };

  const handleDiscountGroupFilterChange = (event: any) => {
    setFilterForm({ ...filterForm, priceDiscountGroup: event.value });
  };

  return (
    <div className="parts-filter-form">
      <form>
        <FormGroup>
          <label>Part number</label>
          <input
            type="text"
            value={filterForm.supplierPartNo || ''}
            onChange={(e) => handleInputChange(e, 'supplierPartNo')}
          />
        </FormGroup>
        <FormGroup>
          <label>Description</label>
          <input
            type="text"
            value={filterForm.supplierDescription || ''}
            onChange={(e) => handleInputChange(e, 'supplierDescription')}
          />
        </FormGroup>
        <FormGroup>
          <label>Discount group</label>
          <DropDown
            field={{ label: null, required: false }}
            showLabel={false}
            height="26px"
            value={filterForm.priceDiscountGroup || ''}
            options={_.isEmpty(props.discountGroups) ? [] : props.discountGroups}
            isLoading={props.isLoadingDiscountGroups}
            onChange={handleDiscountGroupFilterChange}
          />
        </FormGroup>
        <FormGroup className="filter-actions">
          <Button type="button" color="primary" onClick={handleSubmit}>
            Filter
          </Button>
          <Button type="button" color="secondary" onClick={handleClearFilter}>
            Clear
          </Button>
        </FormGroup>
      </form>
    </div>
  );
};

//

const mapDispatchToProps = (dispatch: any) => ({
  filterPartsList: (filterObj: IPartsListFilter) => {
    dispatch({
      type: 'PARTS_LIST_FILTER',
      value: filterObj,
    });
  },
  clearfilterPartsList: (filterObj: IPartsListFilter) => {
    dispatch({
      type: 'PARTS_LIST_FILTER_CLEAR',
      value: filterObj,
    });
  },
});

const mapStateToProps = (state: any, props: any) => {
  const {
    firestore,
    partsListFilter,
  }: {
    firestore: FirestoreReducer.Reducer;
    partsListFilter: { filterObj: IPartsListFilter };
  } = state;
  const params = props.match?.params;
  const discountGroups = firestore.data.priceFiles
    ? firestore.data.priceFiles[params?.priceFileId]?.discountGroups
    : [];
  const isLoadingDiscountGroups = firestore.status.requesting.priceFiles;

  return {
    discountGroups,
    isLoadingDiscountGroups,
    partsListFilter,
  };
};

export default withRouter(
  compose<React.ComponentType<any>>(connect(mapStateToProps, mapDispatchToProps))(PartsFilterForm),
);
