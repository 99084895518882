import _ from 'lodash';
import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  FirebaseReducer,
  WithFirebaseProps,
  ReduxFirestoreQueries,
  firestoreConnect,
  FirestoreReducer,
  getFirebase,
} from 'react-redux-firebase';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose } from 'redux';
import { show } from 'redux-modal';
import Icon from 'react-icons-kit';
import { refreshCw, info, alertTriangle } from 'react-icons-kit/feather';
import styled from 'styled-components';

import history from '../../helpers/history';
import PartsFilterForm from './PartsFilterForm';
import SupplierInfoModal from '../modals/SupplierInfoModal';

import '../../styles/Sidebar.scss';
import { SyncPriceFile } from '../../actions/parts';
import { SaveFtpDetails } from '../../actions/modals';
import { toast } from 'react-toastify';

declare interface SidebarState {
  uploadingPriceFileId: string;
  uploading: boolean;
  show: {
    ftp: boolean;
    supplier: boolean;
  };
  active: {
    group: string | null;
    supplier: string | null;
  };
}

declare interface SidebarProps {
  profile: any;
  vehicleMakes: any;
  priceFiles: any;
  firebase: any;
  partsListFilter: any;
  priceFileId: string;
  uploadingPriceFile: IPriceFile & { id: string };
  showFtpDetails: any;
  supplierGroup: string;
  SyncPriceFile: any;
  show: any;
}

class Sidebar extends Component<
  WithFirebaseProps<any> & RouteComponentProps & SidebarProps,
  SidebarState
> {
  private static INITIAL_STATE: SidebarState = {
    uploadingPriceFileId: '',
    uploading: false,
    show: {
      ftp: false,
      supplier: false,
    },
    active: {
      group: null,
      supplier: null,
    },
  };

  constructor(props: WithFirebaseProps<any> & RouteComponentProps & SidebarProps) {
    super(props);
    this.state = { ...Sidebar.INITIAL_STATE };
    this.sftpReady = this.sftpReady.bind(this);
    this.showFtpSetupModal = this.showFtpSetupModal.bind(this);
  }

  componentDidUpdate() {
    const { uploadingPriceFileId, uploading } = this.state;
    const { uploadingPriceFile, priceFiles, priceFileId } = this.props;
    if (priceFiles && priceFileId === undefined) {
      this.props.history.push(`/${this.props.priceFiles[0].id}`);
    }

    if (
      !uploading &&
      !_.isEmpty(uploadingPriceFile) &&
      uploadingPriceFile.id &&
      uploadingPriceFile.id !== uploadingPriceFileId
    ) {
      this.setState({
        ...this.state,
        uploadingPriceFileId: uploadingPriceFile.id,
      });
    } else if (!uploading && uploadingPriceFile === undefined && uploadingPriceFileId !== '') {
      this.setState({
        uploadingPriceFileId: '',
        uploading: false,
      });
    }
  }

  navigateTo = (route: string) => {
    return () => {
      history.push(route);
    };
  };

  openPriceFileUpdateModal = (groupId: string, priceFileId: string, uploadFailed = false) => {
    const modalContent = {
      content: uploadFailed
        ? 'Do you want to retry?'
        : 'Do you really want to start price file update?',
      additionalContent: uploadFailed ? 'Previous upload failed.' : '',
      handleConfirm: async (dataItem: any, handleHide: any) => {
        if (!_.isUndefined(handleHide)) {
          handleHide();
        }
        this.setState({
          uploadingPriceFileId: priceFileId,
          uploading: true,
        });
        if (_.isFunction(this.props.SyncPriceFile)) {
          await this.props.SyncPriceFile(groupId, priceFileId);
        }
        this.setState({
          ...this.state,
          uploading: false,
        });
      },
    };

    (this.props as any).show('PriceFileUpdateModal', modalContent);
  };

  renderButton = (
    label: string,
    id: string,
    action: () => void,
    metadata: any,
    routes?: string[],
  ): ReactElement => {
    const {
      match: { url },
      uploadingPriceFile,
      profile,
    } = this.props;
    const activeClass = routes && routes.includes(url) ? 'active' : '';
    const groupId = profile && profile.isLoaded ? _.keys(profile.security)[0] : '';
    const { id: priceFileId, sftp, status } = metadata;

    let SidebarButton;

    if (
      this.state.uploadingPriceFileId === id &&
      !_.isEmpty(uploadingPriceFile) &&
      uploadingPriceFile.status.uploading &&
      !uploadingPriceFile.status.success
    ) {
      const alreadyUploadedPercentage =
        (uploadingPriceFile.status.lastChunk * 100) / (uploadingPriceFile.status.totalChunks ?? 1);

      const getBackgroundImageProp = (opacity: string, shade: string) => {
        return `linear-gradient(
            90deg,
            rgba(191, 227, 180, ${opacity}),
            ${alreadyUploadedPercentage}%,
            ${shade} ${alreadyUploadedPercentage + 1}%
          );`;
      };

      SidebarButton = styled.button`
        background-image: ${getBackgroundImageProp('0.8', '#e9edf2')};
        &.active {
          background-image: ${getBackgroundImageProp('0.8', 'rgba(255, 255, 255, 1)')};
        }
        &:hover {
          background-image: ${getBackgroundImageProp('1', '#d8dfe9')};
          &.active {
            background-image: ${getBackgroundImageProp('1', '#d8dfe9')};
          }
        }
      `;
    } else {
      SidebarButton = styled.button``;
    }
    return (
      <div key={id} className="sidebar-button-container">
        <SidebarButton className={`sidebar-button ${activeClass}`} onClick={action}>
          <span>{label}</span>
          {this.state.uploadingPriceFileId === id ? (
            <Icon icon={refreshCw} size={16} className="uploading-icon spin" />
          ) : (
            ''
          )}
        </SidebarButton>

        {this.state.uploadingPriceFileId !== '' || _.isEmpty(sftp) ? (
          <></>
        ) : status && !status?.success && !status?.uploading ? (
          <div
            className="refresh-pricefile"
            onClick={() => this.openPriceFileUpdateModal(groupId, priceFileId, true)}>
            <Icon icon={alertTriangle} size={16} className="failed-upload-icon" />
          </div>
        ) : (
          <div
            className="refresh-pricefile"
            onClick={() => this.openPriceFileUpdateModal(groupId, priceFileId)}>
            <Icon icon={refreshCw} size={16} />
          </div>
        )}
        <div
          className="refresh-pricefile"
          onClick={() =>
            this.setState({
              ...this.state,
              show: { ...this.state.show, supplier: !this.state.show.supplier },
              active: {
                group: groupId,
                supplier: priceFileId,
              },
            })
          }>
          <Icon icon={info} size={16} />
        </div>
      </div>
    );
  };

  sftpReady = () => {
    const { priceFiles, priceFileId, supplierGroup } = this.props;
    return !(_.isUndefined(priceFiles) || _.isEmpty(priceFiles) || !priceFileId || !supplierGroup);
  };

  showFtpSetupModal = () => {
    const { priceFiles, priceFileId, supplierGroup } = this.props;
    if (!this.sftpReady()) {
      return false;
    }
    if (_.isFunction(this.props.show)) {
      const modalContent = {
        confirmButtonColor: 'primary',
        confirmButtonText: 'Submit',
        supplierGroup: supplierGroup,
        priceFileId: priceFileId,
        ftpDetails:
          !_.isUndefined(priceFiles) && !_.isUndefined(priceFileId)
            ? priceFiles.filter((x: any) => x.id === priceFileId)[0].sftp
            : undefined,

        saveFtpDetails: async (ftpDetails: ISftp): Promise<void> => {
          try {
            await SaveFtpDetails(supplierGroup as string, priceFileId as string, ftpDetails);
          } catch (error) {
            toast.error(error.message);
            throw error;
          }
        },
      };
      this.props.show('FtpSetupModal', modalContent);
    }
  };

  render = () => {
    return (
      <div className="sidebar-container">
        <PartsFilterForm></PartsFilterForm>

        <div className="sidebar-buttons">
          {!_.isEmpty(this.props.priceFiles) &&
            this.props.priceFiles.map((priceFileMetadata: any) => {
              const { manufacturer, id } = priceFileMetadata;
              return this.renderButton(
                manufacturer,
                id,
                () => this.props.history.push(`/${priceFileMetadata.id}`),
                priceFileMetadata,
                [`/${priceFileMetadata.id}`],
              );
            })}
        </div>

        <div className="sidebar-bottom">
          <div className="bulk-update">
            <Button
              color="primary"
              disabled={!this.sftpReady()}
              onClick={() => this.showFtpSetupModal()}>
              FTP Setup
            </Button>
          </div>
          <div className="version">version 0.101</div>
        </div>

        <SupplierInfoModal
          show={this.state.show.supplier ?? false}
          group={this.state.active.group}
          supplier={this.state.active.supplier}
          confirmButtonColor="primary"
          confirmButtonText="Submit"
          toggleShow={(show: boolean) =>
            this.setState({
              ...this.state,
              show: { ...this.state.show, supplier: show },
              active: {
                group: !show ? null : this.state.active.group,
                supplier: !show ? null : this.state.active.supplier,
              },
            })
          }
        />
      </div>
    );
  };
}

const preMapStateToProps = ({
  firebase: { profile },
  partsListFilter,
}: {
  firebase: FirebaseReducer.Reducer;
  partsListFilter: { filterObj: IPartsListFilter };
}) => {
  return {
    profile,
    partsListFilter,
  };
};

const mapQueryToProps = ({ profile }: { profile: any }): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  const supplierGroups = Object.keys(profile.security);
  return [
    {
      collection: 'vehicleMakes',
      storeAs: 'vehicleMakes',
    },
    {
      collection: `suppliers/${supplierGroups[0]}/priceFiles`,
      storeAs: 'priceFiles',
    },
  ];
};

const mapStateToProps = (state: any, props: any) => {
  const {
    firestore,
    firebase: { profile },
    partsListFilter,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    partsListFilter: { filterObj: IPartsListFilter };
    modals: { ftpSetup: { show: boolean } };
  } = state;
  const priceFileId = props.match?.params?.priceFileId;

  let priceFiles = firestore.data.priceFiles;
  let vehicleMakes = firestore.data.vehicleMakes;
  let uploadingPriceFile: IPriceFile | {} = {};

  if (vehicleMakes) {
    vehicleMakes = Object.values(vehicleMakes)
      .map((value: any) => value.displayName)
      .sort();
  }

  if (priceFiles) {
    priceFiles = _.map(priceFiles, (value: any, key: string) => {
      return {
        id: key,
        ...value,
      };
    });

    uploadingPriceFile = priceFiles.find((file: any) => {
      return file.status && file.status.uploading;
    });
  }
  let supplierGroups: string[] = [];
  if (profile.isLoaded) {
    supplierGroups = Object.keys((profile as any).security);
  }

  return {
    profile,
    vehicleMakes,
    priceFiles,
    firebase: getFirebase(),
    partsListFilter,
    priceFileId,
    uploadingPriceFile,
    supplierGroup: !_.isEmpty(supplierGroups) ? supplierGroups[0] : '',
  };
};

export default compose<any>(
  withRouter,
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, { show, SyncPriceFile }),
)(Sidebar);
