export const UPDATE_PART = 'UPDATE_PART';
export const REMOVE_PART = 'REMOVE_PART';

export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT';
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT';
export const ADD_DISCOUNT = 'ADD_DISCOUNT';

export const SHOW_FTP_SETUP = 'SHOW_FTP_SETUP';

export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAIL = 'GET_SUPPLIER_FAIL';
export const GET_SUPPLIER_IN_PROGRESS = 'GET_SUPPLIER_IN_PROGRESS';
