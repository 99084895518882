import React from 'react';
import Loader from 'react-loaders';

import './LoaderSmall.scss';

export const LoaderSmall = () => {
  return (
    <div className="load-more-balls small">
      <Loader type="ball-pulse-sync" active={true} />
    </div>
  );
};
