import { GridCell } from '@progress/kendo-react-grid';
import React from 'react';

import { Icon } from 'react-icons-kit';

import { check as checkIcon } from 'react-icons-kit/fa/check';
import { close as closeIcon } from 'react-icons-kit/fa/close';
import { edit as editIcon } from 'react-icons-kit/fa/edit';
import { trashO as trashIcon } from 'react-icons-kit/fa/trashO';

export function GridCellUpdate({
  edit,
  remove,
  update,
  cancel,
  editField,
  onePartInEdit,
  disabled,
}: any) {
  return class extends GridCell {
    render() {
      const { dataItem } = this.props;
      const inEdit = dataItem[editField];

      return inEdit ? (
        <td className="grid-commands">
          <Icon
            icon={checkIcon}
            size={18}
            onClick={() => update(dataItem)}
            className="commands-icon static"
          />
          <Icon
            icon={closeIcon}
            size={18}
            onClick={() => cancel(dataItem)}
            className="commands-icon static"
          />
        </td>
      ) : onePartInEdit || disabled ? (
        <td />
      ) : (
        <td className="grid-commands">
          <Icon
            icon={editIcon}
            size={18}
            onClick={() => edit(dataItem)}
            className="commands-icon"
          />
          <Icon
            icon={trashIcon}
            size={18}
            onClick={() => remove(dataItem)}
            className="commands-icon"
          />
        </td>
      );
    }
  };
}
