import { GridCellProps } from '@progress/kendo-react-grid';
import { Input, InputGroup } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const DiscountEditCell = (props: GridCellProps) => {
  const [dataItem, setDataItem] = useState<any>(null);
  const [dataValue, setDataValue] = useState<number>(0);

  useEffect(() => {
    if (!_.isUndefined(props.field)) {
      setDataItem(props.dataItem);
      setDataValue(props.dataItem[props.field] === null ? 0 : props.dataItem[props.field]);
    }
  }, [props.dataItem, props.field]);

  const handleChange = (e: any) => {
    if (!_.isUndefined(props.onChange)) {
      const value = e.target.value ? e.target.value / 100 : 0;
      props.onChange({
        dataItem: dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: value,
      });
    }
  };

  const parseValue = (value: any) => {
    if (!value) return 0;
    const percentage = 100 * value;
    const percentageFloat = parseFloat(percentage.toFixed(1));
    return percentageFloat;
  };

  return !_.isNull(dataItem) ? (
    <td className="price-retail">
      {!dataItem.inEdit ? (
        <div className="retail-price-cell">
          <span className="amount">{parseValue(dataValue)}</span>
          <span>&nbsp;%</span>
        </div>
      ) : (
        <div>
          <InputGroup>
            <Input
              className="price-edit-field"
              type="number"
              format="#"
              placeholder="0"
              onChange={handleChange}
              value={parseValue(dataValue)}
            />
          </InputGroup>
        </div>
      )}
    </td>
  ) : (
    <td></td>
  );
};

export default DiscountEditCell;
