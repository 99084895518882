import _ from 'lodash';
import React, { ReactElement } from 'react';
import Dropzone from './StyledDropzone';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { MyDropzoneProps } from './typings';

const MyDropzone = ({ field, value, showLabel = true }: MyDropzoneProps) => {
  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="exampleEmail">
          <div>{field.label}</div>
          {field.limit ? (
            <div className="field-limit-indicator">
              {_.size(value)}/{field.limit}
            </div>
          ) : (
            false
          )}
        </Label>
      );
    }
    return false;
  };

  const renderInput = (values: string[]) => {
    return (
      <Dropzone onDrop={(acceptedFiles: any) => console.log(acceptedFiles)}>
        {({ getRootProps, getInputProps }: { getRootProps: any; getInputProps: any }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  return (
    <FormGroup>
      <Row>
        {showLabel && (
          <Col xs="5" style={{ fontSize: '14px' }}>
            {renderLabel()}
          </Col>
        )}
        <Col xs={showLabel ? '7' : '12'}>{renderInput([value])}</Col>
      </Row>
    </FormGroup>
  );
};

export default MyDropzone;
