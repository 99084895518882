import _ from 'lodash';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { show } from 'redux-modal';
import { Grid, GridColumn as Column, GridCellProps } from '@progress/kendo-react-grid';

import { UpdatePart, RemovePart } from '../../actions/parts';
import { GridCellUpdate } from './GridCellUpdate';
import PriceEdit from '../common/PriceEdit/PriceEdit';
import GridMultiText from '../common/GridMultiText/GridMultiText';
import DiscountGroupEdit from '../common/DiscountGroupEdit/DiscountGroupEdit';

import '../../styles/Parts.scss';

const priceRetailFormatted = (props: GridCellProps) => <PriceEdit {...props} />;
const partNumbersOEFormatted = (props: GridCellProps) => <GridMultiText {...props} />;
const discountGroupEdit = (props: GridCellProps, discountGroups: string[]) => (
  <DiscountGroupEdit {...props} discountGroups={discountGroups} />
);

const PartsGrid = (props: any) => {
  const [gridData, setGridData] = useState<any[]>([]);
  const [isInEdit, setIsInEdit] = useState<boolean>(false);
  const [isInEditProcessId, setIsInEditProcessId] = useState<string>('');

  useEffect(() => {
    convertValuesToData(props.values);
    setIsInEdit(false);
  }, [props.values]);

  const convertValuesToData = (values: any) => {
    if (!_.isEmpty(values)) {
      const data = values.map((val: any, index: any) => ({ ...val, id: ++index, inEdit: false }));
      setGridData(data);
    }
  };

  const itemChange = (event: any) => {
    const data = gridData?.map((item: any) =>
      item.id === event.dataItem.id ? { ...item, [event.field]: event.value } : item,
    );
    setGridData(data);
  };

  const enterEdit = (dataItem: any) => {
    setIsInEdit(true);
    setGridData(
      gridData.map((item) => (item.id === dataItem.id ? { ...item, inEdit: true } : item)),
    );
  };

  const removeItem = (data: any, item: any) => {
    const index = data.findIndex((p: any) => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data.splice(index, 1);
    }

    setGridData(data);
  };

  const remove = (dataItem: any, handleHide: () => any) => {
    const data = [...gridData];
    handleHide();

    props.RemovePart(dataItem.supplierPartNo, props.insurer, props.priceFileId).then(() => {
      removeItem(data, dataItem);
    });
  };

  const updateItem = (data: any, item: any) => {
    const index = data.findIndex((p: any) => p === item || (item.id && p.id === item.id));
    if (index >= 0) {
      data[index] = { ...item, inEdit: false };
    }

    setGridData(data);
  };

  const update = async (dataItem: any) => {
    const data = [...gridData];
    const updatedItem = { ...dataItem, inEdit: undefined };

    const updatedPart = {
      partId: updatedItem.partId,
      partNumbersOE: updatedItem.partNumbersOE,
      partStandard: updatedItem.partStandard,
      priceDiscountGroup: updatedItem.priceDiscountGroup,
      priceRetail: updatedItem.priceRetail,
      supplierDescription: updatedItem.supplierDescription,
      supplierPartNo: updatedItem.supplierPartNo,
    };

    setIsInEditProcessId(updatedPart.partId);

    props
      .UpdatePart(updatedPart, props.insurer, props.priceFileId, props.userEmail)
      .then(() => {
        updateItem(data, updatedItem);
      })
      .catch((err: any) => {
        console.error('err:', err);
      })
      .finally(() => {
        setIsInEdit(false);
        setIsInEditProcessId('');
      });
  };

  const cancel = () => {
    convertValuesToData(props.values);
    setIsInEdit(false);
  };

  //

  const openDeletePartModal = (dataItem: any) => {
    const modalContent = {
      title: 'Delete part',
      confirmButtonText: 'Delete',
      confirmButtonColor: 'primary',
      handleConfirm: remove,
      dataItem: dataItem,
    };

    props.show('DeletePartModal', modalContent);
  };

  //

  const gridCellUpdate = GridCellUpdate({
    edit: enterEdit,
    remove: openDeletePartModal,
    update,
    cancel,
    editField: 'inEdit',
    onePartInEdit: isInEdit,
    onePartInEditProcessId: isInEditProcessId,
  });

  //

  return (
    <div className="list-parts" id="list-parts">
      <Grid
        style={{ height: '100%' }}
        data={gridData}
        onScroll={props.scrollHandler}
        onItemChange={itemChange}
        editField="inEdit">
        <Column
          field="supplierPartNo"
          title="Part number"
          className="part-number"
          width="200"></Column>
        <Column
          field="supplierDescription"
          title="Description"
          className="supplier-description"
          width="300"></Column>
        <Column field="partStandard" title="Standard" className="standard" width="90"></Column>
        <Column
          field="priceRetail"
          cell={priceRetailFormatted}
          title="Retail Price (excl. VAT)"
          width="185"></Column>
        <Column
          field="priceDiscountGroup"
          cell={(colProps) => discountGroupEdit(colProps, props.discountGroups)}
          title="Discount Group"
          width="145"></Column>
        <Column
          field="partNumbersOE"
          cell={partNumbersOEFormatted}
          title="Searchable matches"></Column>
        {props.priceFileUploading ? (
          <Column cell={() => <td></td>} title="Edit" width="90" />
        ) : (
          <Column cell={gridCellUpdate} title="Edit" width="90" />
        )}
      </Grid>
    </div>
  );
};

export default connect(null, { UpdatePart, RemovePart, show })(PartsGrid);
