import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import LoadingPage from '../loading/LoadingPage';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default function RequireAuth({ children, ...rest }: any) {
  const auth = useSelector((state: any) => {
    return state.firebase.auth;
  });
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <LoadingPage />;
        }
        if (!isEmpty(auth)) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
