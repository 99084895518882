import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { GridCellProps } from '@progress/kendo-react-grid';
import DropDown from '../DropDown/DropDown';

const DiscountGroupEdit = (props: GridCellProps & { discountGroups: string[] }) => {
  const [dataItem, setDataItem] = useState<any>(null);
  const [dataValue, setDataValue] = useState<string>('');

  useEffect(() => {
    if (!_.isUndefined(props.field)) {
      setDataItem(props.dataItem);
      setDataValue(props.dataItem[props.field] === null ? '' : props.dataItem[props.field]);
    }
  }, [props.dataItem, props.field]);

  const handleChange = (e: any) => {
    if (!_.isUndefined(props.onChange)) {
      props.onChange({
        dataItem: dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  return !_.isNull(dataItem) ? (
    <td className="discount-group-select">
      {dataItem.inEdit ? (
        <DropDown
          field={{ label: null, required: false, className: 'asdasdasdasd' }}
          height="30px"
          showLabel={false}
          value={dataValue}
          options={_.isEmpty(props.discountGroups) ? [] : props.discountGroups}
          isLoading={false}
          onChange={handleChange}
        />
      ) : (
        <div>{dataValue}</div>
      )}
    </td>
  ) : (
    <td></td>
  );
};

export default DiscountGroupEdit;
