import fb from 'firebase/app';

import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import { FIREBASE_CONFIG, IS_LOCAL } from '.';

// init app
fb.initializeApp(FIREBASE_CONFIG);

// init store
fb.firestore();

// init analytics
fb.analytics();

// init performance service
fb.performance();

if (IS_LOCAL) {
  fb.functions().useFunctionsEmulator('http://localhost:5002');
}

export const firebase = fb;
export const auth = firebase.auth();
export const functions = fb.functions();
