import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

const GridMultiText = (props: any) => {
  const [dataItem, setDataItem] = useState<any>(null);
  const [dataValue, setDataValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const components = {
    DropdownIndicator: null,
  };

  useEffect(() => {
    if (!_.isUndefined(props.field)) {
      setDataItem(props.dataItem);
      setDataValue(props.dataItem[props.field] === null ? '' : props.dataItem[props.field]);
    }
  }, [props.dataItem, props.field]);

  const customStyles = {
    control: () => ({
      alignItems: 'center',
      backgroundColor: '#fff',
      borderColor: 'hsl(0,0%,80%);',
      borderRadius: '0',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: '30px',
      outline: '0 !important',
      transition: 'all 100ms',
    }),
    indicatorsContainer: () => ({
      margin: '-4px',
    }),
  };

  const createOptions = (values: string[]) => {
    const optionsArray = values.map((item) => ({ value: item, label: item }));
    return optionsArray;
  };

  const handleInputChange = (val: string) => {
    setInputValue(val);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        const newValue = [...dataValue, inputValue];
        handleChange(createOptions(newValue), null);
        setInputValue('');
        event.preventDefault();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    if (!inputValue) return;
    const newValue = [...dataValue, inputValue];
    handleChange(createOptions(newValue), null);
    setInputValue('');
    event.preventDefault();
  };

  const normalizePartNumber = (partNo: string): string | null => {
    if (!partNo) {
      return null;
    }

    return partNo.replace(/[^a-z0-9]/gi, '').toUpperCase();
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    if (!_.isUndefined(props.onChange)) {
      props.onChange({
        dataItem: dataItem,
        field: props.field,
        syntheticEvent: undefined,
        value: newValue.map((item: { value: string; label: string }) =>
          normalizePartNumber(item.value),
        ),
      });
    }
  };

  return !_.isNull(dataItem) ? (
    <td className="part-numbers-oe">
      {dataItem.inEdit ? (
        <CreatableSelect
          styles={customStyles}
          isMulti
          onChange={handleChange}
          value={createOptions(dataValue)}
          isDisabled={false}
          menuIsOpen={false}
          options={createOptions(dataValue)}
          components={components}
          onKeyDown={handleKeyDown}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          placeholder=""
          onBlur={handleBlur}
        />
      ) : (
        props.dataItem.partNumbersOE?.map((partNumber: string, index: number) => {
          const comma = props.dataItem.partNumbersOE.length > ++index ? ', ' : '';
          return (
            <span key={index}>
              {partNumber}
              {comma}
            </span>
          );
        })
      )}
    </td>
  ) : (
    <td></td>
  );
};

export default GridMultiText;
