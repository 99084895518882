import { functions } from '../config/firebase';
import { Dispatch } from 'redux';
import { GET_SUPPLIER_SUCCESS, GET_SUPPLIER_FAIL, GET_SUPPLIER_IN_PROGRESS } from './ActionTypes';

export const GetSupplierDetails = async (group: string, supplier: string): Promise<unknown> => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: GET_SUPPLIER_IN_PROGRESS,
      payload: { group, supplier },
    });
    const getSupplierDetails = functions.httpsCallable('GetSupplierFromPartsmart');
    getSupplierDetails({ group, supplier })
      .then((result) => {
        dispatch({
          type: GET_SUPPLIER_SUCCESS,
          payload: result.data,
        });
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: GET_SUPPLIER_FAIL,
        });
      });
  };
};
