import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

import { Icon } from 'react-icons-kit';

import { edit as editIcon } from 'react-icons-kit/fa/edit';
import { trashO as trashIcon } from 'react-icons-kit/fa/trashO';
import { check as checkIcon } from 'react-icons-kit/fa/check';
import { close as closeIcon } from 'react-icons-kit/fa/close';

export function GridCellUpdate({
  edit,
  remove,
  update,
  cancel,
  editField,
  onePartInEdit,
  onePartInEditProcessId,
}: any) {
  return class extends GridCell {
    render() {
      const { dataItem } = this.props;
      const inEdit = dataItem[editField];

      if (onePartInEditProcessId === dataItem.partId) {
        return (
          <td>
            <b>Updating...</b>
          </td>
        );
      }

      if (inEdit) {
        return (
          <td className="grid-commands">
            <Icon
              icon={checkIcon}
              size={18}
              onClick={() => update(dataItem)}
              className="commands-icon static"
            />
            <Icon
              icon={closeIcon}
              size={18}
              onClick={() => cancel(dataItem)}
              className="commands-icon static"
            />
          </td>
        );
      }

      if (onePartInEdit) {
        return <td />;
      }

      return (
        <td className="grid-commands">
          <Icon
            icon={editIcon}
            size={18}
            onClick={() => edit(dataItem)}
            className="commands-icon"
          />
          <Icon
            icon={trashIcon}
            size={18}
            onClick={() => remove(dataItem)}
            className="commands-icon"
          />
        </td>
      );
    }
  };
}
