import _ from 'lodash';
import React, { ReactElement } from 'react';
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { DropDownProps } from './typings';

const DropDown = ({
  field,
  value,
  onChange,
  options,
  isLoading,
  showLabel = true,
  disabled = false,
  height = '36px',
}: DropDownProps) => {
  const customStyles = {
    control: () => ({
      alignItems: 'center',
      backgroundColor: disabled ? 'hsl(0,0%,95%)' : '#fff',
      borderColor: disabled ? 'hsl(0,0%,90%)' : 'hsl(0,0%,80%);',
      borderRadius: '0',
      borderStyle: 'solid',
      borderWidth: '1px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      minHeight: height,
      height: height,
      outline: '0 !important',
    }),
  };

  const renderLabel = (): ReactElement | false => {
    if (showLabel) {
      return (
        <Label for="exampleEmail">
          <div>{field.label}</div>
          {field.limit ? (
            <div className="field-limit-indicator">
              {_.size(value)}/{field.limit}
            </div>
          ) : (
            false
          )}
        </Label>
      );
    }
    return false;
  };

  const onChangeInput = (newValue: any, actionMeta: any) => {
    onChange(newValue, actionMeta);
  };

  const createOptions = (values: string[]) => {
    const optionObjects = values?.map((item) => {
      return { value: item, label: item };
    });
    return optionObjects;
  };

  const renderInput = (values: string[]) => {
    return (
      <Select
        styles={customStyles}
        isLoading={isLoading}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        onChange={onChangeInput}
        options={createOptions(options)}
        value={createOptions(values)}
        isDisabled={disabled}
      />
    );
  };

  return (
    <FormGroup>
      <Row className="align-items-center">
        {showLabel && (
          <Col xs="5" style={{ fontSize: '14px' }}>
            {renderLabel()}
          </Col>
        )}
        <Col xs={showLabel ? '7' : '12'}>{renderInput([value])}</Col>
      </Row>
    </FormGroup>
  );
};

export default DropDown;
