import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connectModal } from 'redux-modal';
import 'react-image-gallery/styles/css/image-gallery.css';
import DropDown from '../common/DropDown/DropDown';
import MyDropzone from '../common/Dropzone/MyDropzone';

class BulkUpdateModal extends Component<any> {
  componentDidMount() {
    document.addEventListener('keydown', this.onEsc.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(event: any) {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      this.props.handleHide();
    }
  }

  render() {
    const { title, show, confirmButtonText, confirmButtonColor, handleHide, vehicleMakes } = this.props;
    return (
      <div>
        <Modal isOpen={show} className={this.props.className}>
          <ModalBody className="modal-body">
            <h2>{title}</h2>
            <DropDown
              field={{ label: 'Manufacturer', required: false }}
              value={''}
              options={vehicleMakes}
              isLoading={false}
              onChange={(newValue: any, actionMeta: any) => {}}
            />
            <DropDown
              field={{ label: 'Part Standard', required: false }}
              value={''}
              options={['oe', 'oes', 'oeec', 'oee', 'aftermarket', 'green']}
              isLoading={false}
              onChange={(newValue: any, actionMeta: any) => {}}
            />
            <MyDropzone
              field={{ label: 'File', required: false }}
              value={''}
              isLoading={false}
              onChange={(newValue: any, actionMeta: any) => {}}
            />
          </ModalBody>
          <ModalFooter>
            <Button color={confirmButtonColor || 'danger'} onClick={handleHide}>
              {'Cancel'}
            </Button>
            <Button color={confirmButtonColor || 'danger'} onClick={handleHide}>
              {confirmButtonText || 'Confirm'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export default class DynamicModal extends Component<any> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(BulkUpdateModal);
    return <WrappedMyModal {...this.props} />;
  }
}
