import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connectModal } from 'redux-modal';

import 'react-image-gallery/styles/css/image-gallery.css';
import './SubmitPriceFileUpdateModal.scss';

class SubmitPriceFileUpdateModalContainer extends Component<any> {
  componentDidMount() {
    document.addEventListener('keydown', this.onEsc.bind(this), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onEsc.bind(this), false);
  }

  onEsc(event: any) {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      this.props.handleHide();
    }
  }

  render() {
    const { show, handleHide, handleConfirm, dataItem } = this.props;

    return (
      <div>
        <Modal isOpen={show} className="price-file-update-modal">
          <ModalBody>
            <div className="title">Update Price File</div>
            {this.props.additionalContent && (
              <div className="additional-content">{this.props.additionalContent}</div>
            )}
            <div className="content">{this.props.content}</div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={handleHide}>
              {'Cancel'}
            </Button>
            <Button color="primary" onClick={() => handleConfirm(dataItem, handleHide)}>
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

// tslint:disable-next-line: max-classes-per-file
export class SubmitPriceFileUpdateModal extends Component<any> {
  render() {
    const { name } = this.props;
    const WrappedMyModal = connectModal({ name })(SubmitPriceFileUpdateModalContainer);
    return <WrappedMyModal {...this.props} />;
  }
}
