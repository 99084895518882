import React, { useState, useEffect } from 'react';

const MinutesCron: React.FC<CronProps> = ({ value, onChange, translate }) => {
  const [cron, setCron] = useState<string>('');

  useEffect(() => {
    if (value && value.length > 1) {
      const v = value[1].split('/')[1];
      setCron(v);
    }
  }, [value]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    if ((Number(value) > 0 && Number(value) < 60) || value === '') {
      let val = ['0', '*', '*', '*', '*', '?', '*'];
      val[1] = value ? `0/${value}` : val[1];
      onChange(val);
    }
  };

  return (
    <div className="tab-content">
      <div className="tab-pane active">
        <div className="well well-small">
          {' '}
          {translate('Every')}{' '}
          <input type="number" onChange={onInputChange} value={cron} min={1} max={60} />{' '}
          {translate('minute(s)')}{' '}
        </div>
      </div>
    </div>
  );
};

export default MinutesCron;
