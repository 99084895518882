import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  FirebaseReducer,
  firestoreConnect,
  FirestoreReducer,
  getFirebase,
  ReduxFirestoreQueries,
} from 'react-redux-firebase';
import { compose } from 'redux';
import LoadingOverlay from 'react-loading-overlay';

import Loader from 'react-loaders';
import '../../styles/Parts.scss';
import { IPart } from '../../typings/IPart';
import Parts from './Parts';

declare interface IPartsListProps {
  discountGroups: string[];
  loading: boolean;
  parts: IPart[];
  pagination: any;
  priceFile: IPriceFile;
  priceFileId: string;
  profile: any;

  loadMore: any;
}

const PartsList = (props: IPartsListProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [timeLeft, setTimeLeft] = useState<string>('');

  useEffect(() => {
    if (props.priceFile.status) {
      const secondsRemaining =
        props.priceFile.status.chunkTimeInSeconds *
        (props.priceFile.status.totalChunks - props.priceFile.status.lastChunk);

      let timeLeft = `Estimated time remaining: ${moment()
        .add(secondsRemaining, 'seconds')
        .fromNow(true)}`;
      if (!props.priceFile.status.chunkTimeCalculated) {
        timeLeft = 'Calculating time estimate...';
      }
      setTimeLeft(timeLeft);
    }
  }, [props.priceFile]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const isUploading = props.priceFile.status && props.priceFile.status.uploading;
  return (
    <LoadingOverlay
      active={loading || isUploading}
      spinner={
        props.priceFile.status && props.priceFile.status.uploading ? (
          <div className="loading-priceFile-info">
            <div className="lpi-icon">
              <Loader type="ball-pulse-sync" active={true} />
            </div>
            <div className="lpi-label">Uploading price file</div>
            <div className="lpi-time">{timeLeft}</div>
          </div>
        ) : (
          <div>
            <Loader type="ball-pulse-sync" active={true} />
          </div>
        )
      }
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(255, 255, 255, 0.8)',
        }),
        wrapper: {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        },
      }}>
      {isUploading ? (
        <div className="no-data">&nbsp;</div>
      ) : (
        <Parts
          priceFile={props.priceFile}
          priceFileId={props.priceFileId}
          discountGroups={props.discountGroups}
        />
      )}
    </LoadingOverlay>
  );
};

const preMapStateToProps = (state: any, props: any) => {
  const {
    firebase: { profile },
  }: {
    firebase: FirebaseReducer.Reducer;
  } = state;
  const params = props.match.params;
  return {
    profile,
    params,
  };
};

const mapQueryToProps = ({
  profile,
  params,
}: {
  profile: any;
  params: any;
}): ReduxFirestoreQueries => {
  if (
    !profile.isLoaded ||
    profile.isEmpty ||
    !params ||
    !params.priceFileId ||
    Object.keys(profile.security).length <= 0
  ) {
    return [];
  }

  const supplier = Object.keys(profile.security)[0];

  const priceFile: ReduxFirestoreQueries = {
    collection: `suppliers/${supplier}/priceFiles`,
    doc: params.priceFileId,
    storeAs: 'price_file',
  };

  return [priceFile];
};

const mapStateToProps = (state: any, props: any) => {
  const {
    firestore,
    firebase: { profile },
    pagination,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer & { profile: { security: {} } };
    pagination: { skip: number; take: number };
  } = state;
  const priceFileObj = firestore.data.price_file as IPriceFile;
  const loading = firestore.status.requesting.price_file ?? true;
  const params = props.match.params;
  const priceFileId = params.priceFileId;

  const discountGroups =
    !loading && priceFileObj ? firestore.data.priceFiles[params.priceFileId]?.discountGroups : [];

  return {
    profile,
    loading,
    pagination,
    firebase: getFirebase(),
    discountGroups,
    priceFileId,
    priceFile: priceFileObj ?? {},
  };
};

export default compose<React.ComponentType<any>>(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps),
)(PartsList);
