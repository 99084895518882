import React from 'react';

import Loader from 'react-loaders';
import LoadingOverlay from 'react-loading-overlay';

type LoadingOverlayExtendedType = {
  loading: boolean;
  loadingText?: string;
  children?: React.ReactNode;
};

export const LoadingOverlayExtended = (props: LoadingOverlayExtendedType) => {
  const { children, loading, loadingText } = props;

  return (
    <LoadingOverlay
      active={loading}
      spinner={
        <div>
          <h4 className="loading-text">{loadingText ?? ''}</h4>
          <Loader type="ball-pulse-sync" active={true} />
        </div>
      }
      styles={{
        overlay: (base: any) => ({
          ...base,
          background: 'rgba(255, 255, 255, 0.8)',
        }),
        wrapper: {
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        },
      }}>
      {children}
    </LoadingOverlay>
  );
};
