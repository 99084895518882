import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import cronstrue from 'cronstrue/i18n';
import { metadata, loadHeaders } from './meta/index';
import './cron-builder.css';
import { Label } from 'reactstrap';

const Cron: React.FC<CronBuilderProps> = ({
  value,
  onChange,
  active,
  options,
  locale = 'en',
  translateFn,
  showResultCron,
  showResultText,
}) => {
  const h = loadHeaders(options);
  const [dbCron, setDbCron] = useState<string[]>();
  const [cron, setCron] = useState<string[]>(
    _.isString(value) && value.trim() !== '' ? value.split(' ') : metadata.daily.initialCron,
  );
  const [headers] = useState<ICronHeaderValue[]>(h);
  const [selectedTab, setSelectedTab] = useState<ICronHeaderValue>(h[0]);
  const [systemLocale] = useState<string>(locale);
  useEffect(() => {
    if (value) {
      const val = _.isArray(value) ? value : value.split(' ');
      setCron(val);
      if (!dbCron && _.isString(value) && value.trim() !== '') {
        setDbCron(val);
      }
    }
    if (translateFn && !locale) {
      console.log('Warning !!! locale not set while using translateFn');
    }
  }, [locale, translateFn, value, dbCron]);
  const translate = (key: string) => {
    let translatedText = key;
    if (translateFn) {
      translatedText = translateFn(key);
      if (typeof translatedText !== 'string') {
        throw new Error('translateFn expects a string translation');
      }
    }
    return translatedText;
  };

  const tabChanged = (tab: ICronHeaderValue) => {
    const defaultCron = defaultValue(tab);
    setCron(defaultCron);
    setSelectedTab(tab);
    parentChange(defaultCron);
  };

  const getVal = (cron: string[] | undefined, label: string) => {
    if (!dbCron || !cron || !_.isArray(cron)) {
      return (
        <div>
          <Label className="cron-value-label">{label}</Label>
          <span className="cron-value-text">No active scheduled job</span>
        </div>
      );
    }
    let val = cronstrue.toString(cron.join(' '), {
      locale: systemLocale,
    });

    if (val.search('undefined') === -1) {
      return (
        <div>
          <Label className="cron-value-label">{label}</Label>
          <span className="cron-value-text">{val}</span>
        </div>
      );
    }
  };

  const onValueChange = (val: string[] | undefined) => {
    const defaultValue = metadata.daily.initialCron;
    setCron(val || defaultValue);
    parentChange(val || defaultValue);
  };

  const parentChange = (val: string | string[]) => {
    let newVal = '';
    newVal = val.toString().replace(/,/g, ' ');
    newVal = newVal.replace(/!/g, ',');
    onChange(newVal);
  };

  const getHeaders = () => {
    return headers.map((d, index) => {
      return (
        <li key={index} className={selectedTab === d ? 'active' : ''} onClick={() => tabChanged(d)}>
          <div>{translate(d)}</div>
        </li>
      );
    });
  };

  const defaultValue = (tab: any): string[] => {
    const key: string = headers[headers.indexOf(tab)];
    return metadata[key.toLowerCase()].initialCron;
  };

  const getComponent = (tab: any) => {
    const index = headers.indexOf(tab);
    let selectedMetaData = _.find(metadata, (data) => data.component.name === tab + 'Cron');
    if (!selectedMetaData) {
      const key = headers[index].toLowerCase();
      selectedMetaData = metadata[key];
    }
    if (!selectedMetaData) {
      throw new Error('Value does not match any available headers.');
    }
    const CronComponent = selectedMetaData.component;
    return <CronComponent translate={translate} value={cron} onChange={onValueChange} />;
  };

  return (
    <div className="cron_builder_container">
      <div className="cron_builder">
        <ul className="nav nav-tabs">{getHeaders()}</ul>
        <div className="cron_builder_bordering">{getComponent(selectedTab)}</div>
      </div>
      <div className="cron_schedules">
        {showResultText && active && (
          <div className="cron-builder-bg">{getVal(dbCron, 'Current Schedule:')}</div>
        )}
      </div>
      {showResultCron && (
        <div className="cron-builder-bg">
          {cron.toString().replace(/,/g, ' ').replace(/!/g, ',')}
        </div>
      )}
    </div>
  );
};

export default Cron;
