import React, { Component } from 'react';

export default class AppDefault extends Component {
  render() {
    return (
      <div>
        <div className="container fill-viewport">{this.props.children}</div>
      </div>
    );
  }
}
