/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardBody,
  CardHeader,
  Card,
  Badge,
} from 'reactstrap';
import _ from 'lodash';
import { Steps, Panel } from 'rsuite';
import Icon from 'react-icons-kit';
import { info } from 'react-icons-kit/feather';
import 'rsuite/dist/styles/rsuite-default.css';
import './SupplierInfoModal.scss';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { GetSupplierDetails } from '../../actions/supplier';
import DiscountGrid from './discountGrid/DiscountGrid';
import {
  FirebaseReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  FirestoreReducer,
} from 'react-redux-firebase';
import moment from 'moment';

const SupplierInfoModal = (props: SupplierModalProps & SupplierModalStateToProps) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const { group, supplier, selectedSupplier } = props;
    if (group && supplier && selectedSupplier && !selectedSupplier.loading) {
      props.GetSupplierDetails(group, supplier);
    }
  }, [props.supplier, props.group]);

  const steps = [
    {
      title: 'General',
      icon: info,
    },
    {
      title: 'Discounts',
      icon: info,
    },
  ];

  const renderSupplier = () => {
    if (!props.selectedSupplier?.selectedSupplier) {
      return (
        <div>
          <p className="lead">Could not find matching supplier in Partsmart, please try again.</p>
        </div>
      );
    }
    const {
      selectedSupplier: { selectedSupplier },
      discountsFromDB,
      priceFile,
    } = props;

    return (
      <div>
        <Steps current={step} vertical>
          {_.map(steps, (step, index) => {
            return (
              <Steps.Item
                title={step.title}
                onClick={() => setStep(index)}
                icon={<Icon icon={info} size={18} />}
                key={`step-key-${index}`}
              />
            );
          })}
        </Steps>
        <Panel style={{ overflow: 'visible' }}>
          {step === 0 && (
            <div id="step0">
              <Card>
                <CardHeader>Supply</CardHeader>
                <CardBody>
                  <div className="card-item">
                    <div className="card-item-label">Active:</div>
                    <div className="card-item-content">
                      {selectedSupplier.insurer?.general.active ? 'YES' : 'NO'}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-label">Split Billing:</div>
                    <div className="card-item-content">
                      {selectedSupplier.insurer?.general.paymentProject ? 'YES' : 'NO'}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>Quote</CardHeader>
                <CardBody>
                  <div className="card-item">
                    <div className="card-item-label">Name:</div>
                    <div className="card-item-content">{selectedSupplier.general.name}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-label">Supplier Group:</div>
                    <div className="card-item-content">{props.group}</div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-label">Last update:</div>
                    <div className="card-item-content">
                      {priceFile.status?.lastUpdated
                        ? moment(priceFile.status?.lastUpdated.toDate()).format('YYYY-MM-DD HH:mm')
                        : '-'}
                    </div>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>General</CardHeader>
                <CardBody>
                  <div className="card-item">
                    <div className="card-item-label">Regions:</div>
                    <div className="card-item-content">
                      {_.map(selectedSupplier.general.region, (region, index) => (
                        <Badge key={`region-key-${index}`}>{region}</Badge>
                      ))}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-label">Telephone(s):</div>
                    <div className="card-item-content">
                      {_.map(selectedSupplier.general.telephones, (tel, index) => (
                        <Badge key={`tel-key-${index}`}>{tel}</Badge>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
          {step === 1 && (
            <div id="step1" className="discount-lists">
              {_.isEmpty(discountsFromDB) ? (
                <div>No discounts were found</div>
              ) : (
                <>
                  <Card>
                    <CardHeader>Retail Discount</CardHeader>
                    <CardBody>
                      <DiscountGrid
                        values={discountsFromDB?.retailDiscount}
                        groupId={props.group ?? ''}
                        priceFileId={props.supplier ?? ''}
                        discountGroup="retailDiscount"
                      />
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>Settlement Discount</CardHeader>
                    <CardBody>
                      <DiscountGrid
                        values={discountsFromDB?.settlementDiscount}
                        groupId={props.group ?? ''}
                        priceFileId={props.supplier ?? ''}
                        discountGroup="settlementDiscount"
                      />
                    </CardBody>
                  </Card>
                </>
              )}
            </div>
          )}
        </Panel>
      </div>
    );
  };

  const renderLoader = () => {
    return <div>Loading supplier...</div>;
  };

  const renderSupplierOrLoader = () => {
    if (props.selectedSupplier?.loading) {
      return renderLoader();
    }
    return renderSupplier();
  };

  return (
    <Modal className="supplierInfo" isOpen={props.show}>
      <ModalHeader>Supplier Details</ModalHeader>
      <ModalBody className="modal-body">{renderSupplierOrLoader()}</ModalBody>
      <ModalFooter>
        <Button
          color="secondary"
          onClick={(): void => {
            if (!_.isUndefined(props.toggleShow)) {
              setStep(0);
              props.toggleShow(false);
            }
          }}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const preMapStateToProps = (state: any, props: any) => {
  const {
    firebase: { profile },
  }: {
    firebase: FirebaseReducer.Reducer;
  } = state;

  const { supplier, group } = props;
  return {
    profile,
    supplier,
    group,
  };
};

const mapQueryToProps = ({
  profile,
  supplier,
  group,
}: {
  profile: any;
  supplier: string;
  group: string;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }

  if (supplier && group) {
    const discounts = {
      collection: `suppliers/${group}/priceFiles/${supplier}/insurers`,
      // TODO hardcoded 'santam'
      doc: 'santam',
      storeAs: 'discounts',
    };

    const priceFile = {
      collection: `suppliers/${group}/priceFiles`,
      doc: supplier,
      storeAs: 'priceFile',
    };

    return [discounts, priceFile];
  }

  return [];
};

const mapStateToProps = ({
  firestore,
  supplier,
}: {
  firestore: FirestoreReducer.Reducer;
  supplier: ISupplierReducer;
}) => {
  const discountsObj = firestore.data.discounts;
  const discountsLoading = firestore.status.requesting.discounts ?? true;
  const priceFileObj = firestore.data.priceFile;

  let discounts = {
    settlementDiscount: {},
    retailDiscount: {},
  };

  if (discountsObj && !discountsLoading) {
    if (discountsObj?.retailDiscount) {
      const ordered: { [x: string]: number } = {};
      Object.keys(discountsObj.retailDiscount)
        .sort()
        .forEach((key: string) => {
          ordered[key] = discountsObj.retailDiscount[key];
        });
      discounts.retailDiscount = ordered;
    }

    if (discountsObj?.settlementDiscount) {
      const ordered: { [x: string]: number } = {};
      Object.keys(discountsObj.settlementDiscount)
        .sort()
        .forEach((key: string) => {
          ordered[key] = discountsObj.settlementDiscount[key];
        });
      discounts.settlementDiscount = ordered;
    }
  }

  return {
    selectedSupplier: supplier,
    discountsFromDB: discounts ?? {},
    discountsLoading,
    priceFile: priceFileObj ?? {},
  };
};

export default compose<React.ComponentType<SupplierModalProps>>(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, {
    GetSupplierDetails,
  }),
)(SupplierInfoModal);
