import React from 'react';
import { applyMiddleware, compose, createStore } from 'redux';
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import { firebase } from './config/firebase';
import { Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import {
  getFirebase,
  ReactReduxFirebaseConfig,
  ReactReduxFirebaseProvider,
} from 'react-redux-firebase';

import reducers from './store/reducers';
import history from './helpers/history';
import RouteWithLayout from './helpers/RouteWithLayout';
import AppDefault from './wrappers/AppDefault';
import LoginPage from './components/auth/LoginPage';
import AppWithSidebar from './wrappers/AppWithSidebar';
import PartsList from './components/parts/PartsList';

import '@progress/kendo-theme-default/dist/all.css';
import { IS_PROD } from './config';

// Middlewares
const middlewares = [ReduxPromise, thunk.withExtraArgument({ getFirebase, getFirestore })];
const initialState = {};

// store with reducers and initial state
const store = createStore(reducers, initialState, compose(applyMiddleware(...middlewares)));

if (!IS_PROD) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  favicon.href = '/favicon_red.ico';
}

// react-redux-firebase config
const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true,
};

// react-redux-firebase provider
const rrfProps = {
  firebase,
  createFirestoreInstance,
  config: rrfConfig,
  dispatch: store.dispatch,
};

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router history={history}>
          <Switch>
            <RouteWithLayout layout={AppDefault} path="/login" component={LoginPage} />
            <RouteWithLayout
              layout={AppWithSidebar}
              path="/:priceFileId"
              component={PartsList}
              requiresAuth={true}
            />
            <RouteWithLayout
              layout={AppWithSidebar}
              path="/"
              component={PartsList}
              requiresAuth={true}
            />
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
