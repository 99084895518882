import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  ReduxFirestoreQueries,
  ReduxFirestoreQuerySetting,
  FirestoreReducer,
  FirebaseReducer,
  WhereOptions,
  getFirebase,
  firestoreConnect,
} from 'react-redux-firebase';
import { IPart } from '../../typings/IPart';
import PartsGrid from './PartsGrid';
import { withRouter } from 'react-router-dom';

declare interface IPartsProps {
  discountGroups: string[];
  insurer?: string;
  loading?: boolean;
  priceFile: IPriceFile;
  priceFileId: string;
  parts?: IPart[];
  pagination?: any;
  profile?: any;
  loadMore?: any;
}

const Parts = (props: IPartsProps) => {
  const [parts, setParts] = useState<any[]>([]);
  const [priceFileId, setPriceFileId] = useState('');

  useEffect(() => {
    setParts([]);
  }, [props.priceFileId]);

  useEffect(() => {
    if (!_.isEmpty(props.parts) && props.priceFileId !== priceFileId) {
      setPriceFileId(props.priceFileId);
      copyToGridData(props.parts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.parts, props.priceFileId]);

  const fetchMoreData = () => {
    props.loadMore(props.pagination.take + 50);
  };

  const scrollHandler = (event: any) => {
    const e = event.nativeEvent;
    if (
      e.target.scrollTop >= e.target.scrollHeight - e.target.clientHeight &&
      e.target.className !== 'k-textbox'
    ) {
      fetchMoreData();
    }
  };

  const copyToGridData = (values: any) => {
    if (!_.isEmpty(values)) {
      const data = values.map((val: any, index: any) => ({ ...val, id: index, inEdit: false }));
      setParts(data);
    }
  };
  if (props.loading && _.isEmpty(parts)) {
    return <div></div>;
  }
  if (_.isEmpty(parts)) {
    return <div className="no-data">No Data</div>;
  }
  return (
    <PartsGrid
      values={parts}
      scrollHandler={scrollHandler}
      priceFileId={props.priceFileId}
      insurer={props.insurer}
      priceFileUploading={props.priceFile.status && props.priceFile.status.uploading}
      userEmail={props.profile.email}
      discountGroups={props.discountGroups}
    />
  );
};

const preMapStateToProps = (state: any, props: any) => {
  const {
    firebase: { profile },
    pagination,
    partsListFilter,
  }: {
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    partsListFilter: { filterObj: IPartsListFilter };
  } = state;
  const params = props.match.params;
  return {
    profile,
    pagination,
    partsListFilter,
    params,
  };
};

const mapQueryToProps = ({
  profile,
  pagination,
  partsListFilter,
  params,
}: {
  profile: any;
  pagination: { skip: number; take: number };
  partsListFilter: { filterObj: IPartsListFilter };
  params: any;
}): ReduxFirestoreQueries => {
  if (
    !profile.isLoaded ||
    profile.isEmpty ||
    !params ||
    !params.priceFileId ||
    Object.keys(profile.security).length <= 0
  ) {
    return [];
  }
  const supplier = Object.keys(profile.security)[0];
  const parts: ReduxFirestoreQuerySetting = {
    collection: `suppliers/${supplier}/priceFiles/${params.priceFileId}/parts`,
    limit: pagination.take,
    storeAs: 'parts_list',
  };

  let whereFilters: WhereOptions[] = [];

  for (let [key, value] of Object.entries(partsListFilter)) {
    if (value !== null && value !== '') {
      whereFilters.push([key, '==', value]);
    }
  }

  if (whereFilters.length > 0) {
    parts.where = whereFilters;
  }
  return [parts];
};

const mapStateToProps = (state: any, props: any) => {
  const {
    firestore,
    firebase: { profile },
    pagination,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer & { profile: { security: {} } };
    pagination: { skip: number; take: number };
  } = state;
  const partsObj = firestore.data.parts_list;
  const priceFileObj = firestore.data.price_file as IPriceFile;
  const params = props.match.params;
  const priceFileId = params.priceFileId;
  const discountGroups = firestore.data.priceFiles
    ? firestore.data.priceFiles[params.priceFileId]?.discountGroups
    : [];
  const loading = firestore.status.requesting.parts_list ?? true;

  let partsArr = [];

  if (!loading) {
    if (!_.isEmpty(partsObj)) {
      partsArr = Object.keys(partsObj).map((key) => {
        return {
          partId: key,
          ...partsObj[key],
        };
      });
    }
  }

  let insurer = '';

  if (profile.isLoaded && !profile.isEmpty && Object.keys(profile.security).length > 0) {
    insurer = Object.keys(profile.security)[0];
  }

  return {
    profile,
    parts: partsArr,
    loading,
    pagination,
    firebase: getFirebase(),
    discountGroups,
    insurer,
    priceFileId,
    priceFile: priceFileObj ?? {},
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  loadMore: (skip: number) => {
    dispatch({
      type: 'LOAD_MORE',
      value: skip,
    });
  },
  resetPaging: () => {
    dispatch({
      type: 'RESET_PAGINATION',
    });
  },
});

export default compose<React.ComponentType<IPartsProps>>(
  withRouter,
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(Parts);
