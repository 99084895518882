import { actionTypes } from 'redux-firestore';

const INIT = {
  skip: 0,
  take: 50,
  lastVisible: null,
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA:
      return { ...INIT };
    case 'LOAD_MORE':
      return {
        ...state,
        take: action.value,
      };
    case 'LAST_VISIBLE':
      return {
        ...state,
        lastVisible: action.value,
      };
    case 'RESET_PAGINATION':
      return { ...state };
    default:
      return { ...state };
  }
};
