import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from '../components/common/Header';
import Sidebar from '../components/common/Sidebar';
import BulkUpdateModal from '../components/modals/BulkUpdateModal';
import DeletePartModal from '../components/modals/DeletePartModal';
import { SubmitPriceFileUpdateModal } from '../components/modals/SubmitPriceFileUpdateModal';
import { FtpSetupModal } from '../components/modals/FtpSetupModal';
import { DeleteDiscountModal } from '../components/modals/deleteDiscount/DeleteDiscountModal';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/Wrapper.scss';

export default class AppWithSidebar extends Component<RouteComponentProps> {
  render() {
    return (
      <div>
        <Header />
        <div className="app-container">
          <div className="app-sidebar">
            <Sidebar />
          </div>
          <div className="app-main">{this.props.children}</div>
        </div>
        <ToastContainer />
        <BulkUpdateModal name="BulkUpdateModal" className="images-modal v2-modal" />
        <DeletePartModal name="DeletePartModal" />
        <DeleteDiscountModal name="DeleteDiscountModal" />
        <SubmitPriceFileUpdateModal name="PriceFileUpdateModal" />
        <FtpSetupModal name="FtpSetupModal" />
      </div>
    );
  }
}
