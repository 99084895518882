import _ from 'lodash';
import React, { useState } from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';
import { metadata } from '../meta';

const WeeklyCron: React.FC<CronProps> = ({ value, translate, onChange }) => {
  const [cron, setCron] = useState(value || metadata.weekly.initialCron);

  const updateCronAt = (index: number[], value: string[]): void => {
    let val = [...cron];
    _.map(index, (i, x) => {
      val[i] = value[x];
    });
    setCron(val);
    onChange(val);
  };

  const onAtHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([0, 2], ['0', `${e.target.value}`]);
  };

  const onAtMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([0, 1], ['0', `${e.target.value}`]);
  };

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = [...cron];
    val[0] = '0';
    if (e.target.checked) {
      onDayChecked(val, e);
    } else {
      onDayUnChecked(val, e);
    }
    onChange(val);
  };

  const onDayChecked = (val: string[], e: React.ChangeEvent<HTMLInputElement>) => {
    val[2] = `${val[2]}`.split('/').length > 1 ? '0' : val[2].toString();
    val[3] = '?';
    val[4] = '*';
    if (val[5] === '*' || val[5] === '?' || val[5] === 'MON-FRI') {
      val[5] = e.target.value;
    } else {
      val[5] = val[5] + '!' + e.target.value;
    }
    setCron(val);
  };

  const onDayUnChecked = (val: string[], e: React.ChangeEvent<HTMLInputElement>) => {
    const days = val[5].split('!');
    let newVal = '';
    if (days.length > 1) {
      days.splice(days.indexOf(e.target.value), 1);
      newVal = days.toString().replace(/,/g, '!');
    } else {
      newVal = '*';
    }
    updateCronAt([5], [newVal]);
  };

  const renderCheckBox = (v: string) => {
    const days: { [key: string]: string } = {
      MON: 'Monday',
      TUE: 'Tuesday',
      WED: 'Wednesday',
      THU: 'Thursday',
      FRI: 'Friday',
      SAT: 'Saturday',
      SUN: 'Sunday',
    };
    return (
      <div key={`chkbox-${v}`}>
        <input
          type="checkbox"
          value={v}
          onChange={onCheck}
          checked={cron[5].search(v) !== -1 ? true : false}
        />
        {translate(days[v])}
        <br />
      </div>
    );
  };

  const leftDays = ['MON', 'WED', 'FRI', 'SUN'];
  const rightDays = ['TUE', 'THU', 'SAT'];

  return (
    <div className="container-fluid">
      <div className="well well-small row">
        <div className="span6 col-sm-6">
          <div className="text_align_left">{_.map(leftDays, renderCheckBox)}</div>
        </div>
        <div className="span6 col-sm-6">
          <div className="text_align_left">{_.map(rightDays, renderCheckBox)}</div>
          <br />
          <br />
        </div>
      </div>
      {translate('Start time')}
      <Hour onChange={onAtHourChange} value={cron[2]} />
      <Minutes onChange={onAtMinuteChange} value={cron[1]} />
    </div>
  );
};
export default WeeklyCron;
