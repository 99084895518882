import React from 'react';
import Loader from 'react-loaders';

// import { Helmet }  from 'react-helmet';

import '../../styles/LoadingPage.scss';

const LoadingPage: React.FC = () => {
  return (
    <div className="fill flex center loading-page">
      {/* 
        TODO for now I have to comment this 
        because nothing is unchanging this 'Loading...' after it has been set 
      */}
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Loading...</title>
      </Helmet> */}
      <div className="loading-container">
        <Loader type="ball-pulse-sync" active={true} />
      </div>
    </div>
  );
};

export default LoadingPage;
