import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Minutes from '../minutes-select';
import Hour from '../hour-select';
import { metadata } from '../meta';

const DailyCron: React.FC<CronProps> = ({ value, translate, onChange }) => {
  const [every, setEvery] = useState<boolean>(true);
  const [cron, setCron] = useState<string[]>(value || metadata.daily.initialCron);
  useEffect(() => {
    if (value && !_.isEmpty(value) && every) {
      const newEvery = value[3] !== '?';
      setEvery(newEvery);
    }
  }, [value, every]);

  const onDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    if (!value || (Number(value) > 0 && Number(value) < 32)) {
      const newValue = ['0', getValueByIndex(1), getValueByIndex(2), '*', '*', '?', '*'];
      setCron(newValue);
      const day = value ? `*/${value}` : value;
      console.log(day);
      updateCronAt([3], [day]);
    }
  };

  /**
   * If value is * return 0 else return value
   * @param {position in array} index
   */
  const getValueByIndex = (index: number) => {
    return cron[index] === '*' ? '0' : cron[index];
  };

  const updateCronAt = (index: number[], value: string[]): void => {
    let val = [...cron];
    _.map(index, (i, x) => {
      val[i] = value[x];
    });
    setCron(val);
    console.log(`updating daily`, val, value, cron);
    onChange(val);
  };

  const onAtHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([2], [e.target.value]);
  };

  const onAtMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCronAt([1], [e.target.value]);
  };

  return (
    <div className="tab-pane">
      <div className="well well-small">
        <input
          type="radio"
          onChange={(e) => {
            setEvery(true);
            setCron(['0', getValueByIndex(1), getValueByIndex(2), '*', '*', '?', '*']);
            onChange(['0', getValueByIndex(1), getValueByIndex(2), '*', '*', '?', '*']);
          }}
          value="1"
          name="DailyRadio"
          checked={every}
        />{' '}
        <span> {translate('Every')} </span>{' '}
        <input
          disabled={!every}
          type="Number"
          maxLength={2}
          onChange={onDayChange}
          value={cron[3] && cron[3].split('/')[1] ? cron[3].split('/')[1] : ''}
        />{' '}
        <span> {translate('day(s)')} </span>{' '}
      </div>{' '}
      <div className="well well-small">
        <input
          onChange={(e) => {
            setEvery(false);
            setCron(['0', cron[1], cron[2], '?', '*', 'MON-FRI', '*']);
            onChange(['0', cron[1], cron[2], '?', '*', 'MON-FRI', '*']);
          }}
          type="radio"
          value="2"
          name="DailyRadio"
          checked={!every}
        />{' '}
        <span> {translate('Every week day')} </span>{' '}
      </div>{' '}
      <div className="well well-small">
        <span> {translate('Start time')} </span> <Hour onChange={onAtHourChange} value={cron[2]} />{' '}
        <Minutes onChange={onAtMinuteChange} value={cron[1]} />{' '}
      </div>
    </div>
  );
};

export default DailyCron;
