import _ from 'lodash';
import {
  GET_SUPPLIER_FAIL,
  GET_SUPPLIER_SUCCESS,
  GET_SUPPLIER_IN_PROGRESS,
} from '../../actions/ActionTypes';

const INIT = {
  success: false,
  loading: false,
  suppliers: [],
  group: null,
  priceFile: null,
  selectedSupplier: null,
};

export default (state = INIT, action: any) => {
  switch (action.type) {
    case GET_SUPPLIER_IN_PROGRESS:
      return {
        ...state,
        group: action.payload.group,
        priceFile: action.payload.supplier,
        loading: true,
        success: false,
      };
    case GET_SUPPLIER_SUCCESS:
      const suppliers = action.payload;
      const findPriceFile = (pricefile: { pricefile: string; make: string }[]): boolean => {
        return _.some(pricefile, (p) => p.pricefile === state.priceFile);
      };
      const selectedSupplier = _.find(suppliers, (supplier) => {
        return _.some(supplier.general.quote.api, (api) => findPriceFile(api.pricefile) === true);
      });

      return {
        ...state,
        success: true,
        loading: false,
        suppliers: suppliers,
        selectedSupplier,
        pricing: action.payload.insurer?.pricing,
      };
    case GET_SUPPLIER_FAIL:
      console.error('FAILED SGET SUPPLIER');
      return {
        ...state,
        success: false,
        loading: false,
        lastVisible: action.value,
      };
    default:
      return { ...state };
  }
};
